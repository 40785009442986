import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { callApi } from "../../../components/src/Toolkit";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { IUserDetails } from "../../../components/src/interfaces.web";
import { getLastPartOfURL } from "../../../components/src/utilities";
import { paymentFailed, paymentSucessfull } from "./assets";

declare let Cashfree: any;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  totalWalletBalance:number,
  loading:boolean,
  amountEntered:number,
  amountInputError:string,
  insufficiantAmount:boolean,
  isFromBank:boolean,
  transectionStatusPopupContent:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddMoneyController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    walletBalaceApi=""
    createOrderAPICallId=""
    cashfree: any;
    transectionStatusApiCallId=""
    walletTransectionApiCallId=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            totalWalletBalance:0,
            loading:false,
            amountEntered:1,
            amountInputError:"",
            insufficiantAmount:false,
            isFromBank:false,
            transectionStatusPopupContent:{},
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            
            if(responseJson) {
              switch (apiRequestCallId) {
                case this.walletBalaceApi:
                  this.setState({totalWalletBalance:responseJson.total_amount})
                  break
                case this.createOrderAPICallId:
                  this.handlePaymentReceive(responseJson)
                  break;
                case this.transectionStatusApiCallId:
                  const status = responseJson.find((elm: any) => elm.is_captured === true)
                  this.setState({
                    transectionStatusPopupContent: status 
                      ? {image:paymentSucessfull, message:"Transaction Successful!"} 
                      : {image:paymentFailed, message:"Transaction Failed!"}
                  })
                  this.walletTransection("credit", status ? "success" : "failed", responseJson[0]);
                  break;
              }
            }
            this.setState({loading:false});
          }
        // Customizable Area End
    }

    // Customizable Area Start

  async componentDidMount() {
    window.scrollTo(0,0);
    const urlParams = getLastPartOfURL()
    let isCashFree = await getStorageData(LocalStorageKeys.IsCashFree);

    if (isCashFree === "true") {
      this.getTransectionStatus(urlParams);
      await setStorageData(LocalStorageKeys.IsCashFree,"false");
    }else{
      this.getWalletBalace();
      this.setState({ isFromBank: urlParams === "Bank" ? true : false })
    }
  }

  handleBackBtn = () => {
    this.props.navigation.goBack();
  }

  // to get the total balance in the wallet
  getWalletBalace = async () => {
    this.setState({ loading: true })
    this.walletBalaceApi = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.GetMethod,
      endPoint: configJSON.walletBalance,
      headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
    }, runEngine)
  }

  handleChangeAmount = (event: any, value: any) => {
      if(parseInt(value) > 10000){
        this.setState({amountInputError:"Please enter an amount less than 10000"})
      }else{
        this.setState({ amountEntered: value, amountInputError:"" });
      }
  }

  // create order for payment on click of proceed button
  handleProceed = async () => {
    const isForBank = getLastPartOfURL()
    if (isForBank === "Bank") {
      if (this.state.amountEntered > this.state.totalWalletBalance) {
        this.setState({insufficiantAmount:true});
      } else {
        setStorageData("amountTransfered", this.state.amountEntered);
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "BankAccountsList");
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationScreenNameMessage), "");
        this.send(msg);
      }
    } else {
      const httpBody = await this.getPayloadForPayment()
      this.createOrderAPICallId = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.PostMethod,
        endPoint: configJSON.createOrderEndPoint,
        body: httpBody,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
      }, runEngine)
    }
  }

  getPayloadForPayment = async () => {

    const { id, attributes: { email, full_phone_number, full_name } }: IUserDetails =
      await getStorageData(LocalStorageKeys.UserDetails, true)

    return {
      customer_details: {
        customer_id: id,
        customer_email: email ?? "test@123gmail.com",
        customer_phone: full_phone_number ? full_phone_number.split("").splice(2).join(""):"9898989898",
        customer_name: full_name ?? "test"
      },
      order_amount: this.state.amountEntered,
      order_currency: "INR"
    }
  }

  handlePaymentReceive = async (resJson: any) => {
    let checkoutOptions = {
      paymentSessionId: resJson.payment_session_id,
      returnUrl: window.location.origin + "/addMoney/" + resJson.order_id
    }

    const cashfree = await Cashfree({
      mode: "sandbox" //or production
    });

    cashfree.checkout(checkoutOptions).then(async function (result: any) {
      if (result.error) {
        alert(result.error.message)
      }
      if (result.redirect) {
        await setStorageData(LocalStorageKeys.IsCashFree, "true");
      }
    });
  }

  handleOnClickDone = () => {
    this.setState({ insufficiantAmount: false });
  }

  getTransectionStatus = async(orderId:any) => {
    this.setState({loading:true})
    this.transectionStatusApiCallId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.GetMethod,
      endPoint: configJSON.getTransectionStatusEndPoint+orderId,
      headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
    }, runEngine)
  }

  walletTransection = async(transaction_type:string, status:string, respJson:any) =>{
    const httpBody = {
      wallet_transaction: {
        description: "Money added to Wallet",
        amount: respJson.order_amount,
        transaction_type: transaction_type, //"debit"
        status: status, //success", "failed", "pending
        payment_order_id: respJson.order_id
    }
    }
      this.walletTransectionApiCallId = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.PostMethod,
        endPoint: configJSON.walletTransectionEndPoint,
        body: httpBody,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
      }, runEngine)
  }

  navigateToWallet = async() =>{
    let redirectTo = await getStorageData(LocalStorageKeys.RedirectTo) ?? "Cfwallet19";
    let stringAray = redirectTo.split('/')
    await setStorageData(LocalStorageKeys.RedirectTo, "Cfwallet19")

    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), stringAray[0]);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    stringAray.length > 1 && msg.addData(getName(MessageEnum.NavigationScreenNameMessage), stringAray[1]);
    this.send(msg);
    
  }
    // Customizable Area End
}
