// Customizable Area Start
import { Box, Button, Container, Divider, Grid, Paper, Switch, Typography, makeStyles } from "@material-ui/core";
import { CheckCircleOutlined, PlaceOutlined } from "@material-ui/icons";
import React from "react";
import { SeatStatus } from "./enums.web";
import moment from "moment";
import { convertToPad } from "./utilities";

enum BookingType {
  Event,
  Movie
}

const useStyles = makeStyles((theme) => ({
  Wrapper: {
    fontFamily: "Poppins",
    padding: "70px 0",
  },
  Title: {
    marginTop: "0.75rem",
    color: theme.palette.primary.main,
  },
  TitleWrapper: {
    marginBottom: "35px",
  },
  CheckIcon: {
    color: theme.palette.primary.main,
    fontSize: "48px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
    }
  },
  TicketWrapper: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    border: "none",
    padding: "24px",
    [theme.breakpoints.down('sm')]: {
      padding: "16px",
    },
  },
  ContainerWrapper: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  Line: {
    width: "2px",
    height: "96%",
    background: "repeating-linear-gradient(0deg, #656363 0 4px, transparent 0 7px)",
    position: "absolute",
    right: "0",
    top: "4px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  TicketFirstPart: {
    position: "relative",
    flex: 11,
    [theme.breakpoints.down('sm')]: {
      flex: 1
    },
    [theme.breakpoints.down("md")]: {
      flex: 1
    }
  },
  TicketSecondPart: {
    flex: 9,
    minHeight: "280px",
    [theme.breakpoints.down('sm')]: {
      paddingTop: "24px",
      flex: 1,
      display: "none"
    },
    [theme.breakpoints.down("md")]: {
      flex: 1,
    }
  },
  MobileQRWrapper: {
    position: "absolute",
    right: "0",
    top: "5px",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  TopElement: {
    content: '""',
    position: 'absolute',
    top: "-54px",
    right: "-24px",
    width: '48px',
    height: '48px',
    borderRadius: '64px',
    backgroundColor: "#121212",
    [theme.breakpoints.down('sm')]: {
      left: "-37px",
      bottom: "unset",
      top: "137px",
      width: '32px',
      height: '32px',
    },
  },
  BottomElement: {
    content: '""',
    position: 'absolute',
    bottom: "-56px",
    right: "-26px",
    width: '48px',
    height: '48px',
    borderRadius: '64px',
    backgroundColor: "#121212",
    [theme.breakpoints.down('sm')]: {
      right: "-35px",
      bottom: "unset",
      top: "137px",
      width: '32px',
      height: '32px',
    },
  },
  PosterDetailsWrapper: {
    display: "flex",
    gap: "16px",
  },
  MoviePoster: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "210px",
    minWidth: "135px",
    aspectRatio: "0.86"
  },
  PosterImage: {
    objectFit: "fill",
    width: "170px",
    aspectRatio: "0.85",
    border: "solid 0.7px",
    padding: "0 !important",
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      width: "90px",
      aspectRatio: "0.74"
    }
  },
  MovieTitle: {
    maxWidth: "320px",
    lineHeight: 1.25,
  },
  CategoryWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    margin: "16px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0"
    }
  },
  CategorySpan: {
    fontSize: "14px",
    fontFamily: "Poppins",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    padding: "8px",
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "4px",
    }
  },
  TimingDetails: {
    marginTop: "32px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
    }
  },
  TicketDetails: {
    gap: "32px",
    marginTop: "8px"
  },
  SectionDetails: {
    display: "flex",
    gap: "28px",
    flexWrap: "wrap"
  },
  ItoSecondPartWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "4px 0 0 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  },
  ITODetailsWrapper: {
    display: "none",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "4px 0 0 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      display: "flex"
    }
  },
  LocationIcon: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    marginRight: "6px",
  },
  SeatWrapper: {
    display: 'flex',
    gap: "8px",
    flexWrap: "wrap",
    marginTop: "5px",
  },
  Seat: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "32px",
    height: "32px",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Poppins",
    borderRadius: "4px",
    color: "#000",
    "&.selected": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  EventSeat:{
    padding:"0 10px",
    height: "32px",
    fontSize: "14px",
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000",
    fontFamily: "Poppins",
    borderRadius: "4px",
    backgroundColor: "#ffb43a",
    minWidth: "80px",
  },
  TheaterName: {
    fontSize: "16px",
    fontFamily: "Poppins",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  AmountText: {
    fontSize: "20px",
    borderTop: "1.5px dotted #b8b8b8",
    borderBottom: "1.5px dotted #b8b8b8",
    padding: "24px 0",
    fontFamily: "Poppins",
  },
  PriceWrapper: {
    width: "281px",
  },
  Price: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  RupeesSymbol: {
    fontFamily: 'Roboto-Medium, sans-serif'
  },
  SubmitButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600,
    borderRadius: "12px",
    height: "56px",
    color: "#000",
    "&.MuiButton-root": {
      textTransform: 'none',
      fontSize: "1.125rem",
    },
    "&:hover": {
      color: "#000",
      backgroundColor: theme.palette.primary.main
    }
  },
  QRWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  QRCode: {
    width: "132px",
    height: "132px",
    objectFit: 'contain',
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px"
    }
  },
  QRText: {
    fontSize: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  QRId: {
    fontWeight: 600,
    fontSize: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  DisplayFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 'normal',
  },
  DottedDivider: {
    height: "1px",
    background: "repeating-linear-gradient(90deg, #656363 0 3px, transparent 0 6px)",
    margin: "22px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "12px 0"
    }
  },
  CaptionText: {
    fontSize: "14px",
    fontWeight: 600,
    marginRight: "14px",
  },
  Font500Weight: {
    fontWeight: 500,
    textTransform: "uppercase",
  },
  CustomSwitch: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        "& .MuiSwitch-thumb": {
          backgroundColor: "#000 !important",
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      backgroundColor: "gray",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#121212",
      boxSizing: 'border-box',
    },
  },
  DisplayFlexCaption: {
    display: "flex",
    alignItems: "center",
    margin: "26px 0 0 0",
    [theme.breakpoints.down('md')]: {
      margin: "0 0 12px",
      display: "none"
    }
  },
  Margin4Mui: {
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
    }
  },
  OverFlow: {
    overflow: 'hidden',
  },
  SeatTierIto: {
    textTransform: "capitalize"
  },
  MobileFooter: {
    display: "none",
    position: "fixed",
    left: "16px", 
    right: "16px",
    bottom: "16px",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  }
}))

interface BookingSuccessfulTicketProps {
  bookingData: any
  isApiLoading: boolean
  mTicketEnabled: boolean
  isForEvent: string
  handleChangeMTicket: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleList: () => void
  handleListEvent: () => void
}

const BookingSuccessfulTicket: React.FC<BookingSuccessfulTicketProps> = (props) => {
  const classes = useStyles();
  const attributes = props.bookingData.attributes;

  const ticketData = props.isForEvent === "event" ? {
    type: BookingType.Event,
    image_url: attributes.poster_url[0],
    name: attributes.event.name,
    entertainmentType: attributes.event.show_type,
    language: attributes.language.map((item: any, ind: number) => `${item.name} ${attributes.language.length - 1 != ind ? ', ' : ""}`),
    place_Name: attributes.venue.full_address,
    screen_Name: attributes.tier,
    number_Of_Seats: attributes.total_seats,
    qrCode: attributes?.qr_code,
    uniq_booking_id: attributes.uniq_booking_id,
    total_amount: attributes.total_amount,
    ito_status: attributes.ito_status,
    seat_allocation_type: attributes.seat_allocation_type,
    seat_id_number:attributes.seats,
    date: [attributes.schedule.schedule_date, moment(attributes.schedule.start_time, ["HH:mm"]).format('hh:mm A')]
  } : {
    image_url: attributes?.movie.image_url,
    name: attributes?.movie.name,
    entertainmentType: ["Animation", "Action"],
    language: `${attributes?.language} | ${attributes?.screen_type}`,
    place_Name: attributes?.theatre_name,
    screen_Name: attributes?.screen_name,
    number_Of_Seats: attributes?.number_of_seats,
    seat_id_number: attributes?.seat_id_number,
    qrCode: attributes?.qr_code,
    uniq_booking_id: attributes?.uniq_booking_id,
    total_amount: attributes?.total_amount,
    date: attributes?.show_date_time.split(" | "),
    ito_status: attributes?.movie?.is_ito_live,
    type: BookingType.Movie,
    seat_tier: attributes?.price_structure?.seat_type
  }

  return (
    <Box className={classes.Wrapper}>
      {
        ticketData.ito_status ?
          <Container className={classes.OverFlow}>
            <Box className={classes.TitleWrapper} textAlign="center">
              <CheckCircleOutlined className={classes.CheckIcon} />
              <Typography align="center" variant="h2" className={classes.Title} data-test-id="booking" >ITO Application Successful!</Typography>
            </Box>
            <Paper elevation={0} className={classes.TicketWrapper}>

              <Box className={classes.ContainerWrapper}>
                <Box className={classes.TicketFirstPart}>
                  <div className={classes.Line}></div>
                  <div className={classes.TopElement}></div>
                  <div className={classes.BottomElement}></div>
                  <Box className={classes.PosterDetailsWrapper}>

                    <Box>
                      <img className={classes.PosterImage} src={ticketData.image_url} alt="Movie Poster" />
                    </Box>

                    <Box>
                      <Typography variant="subtitle1" className={classes.MovieTitle}>{ticketData.name}</Typography>

                      <Box mt={2} mb={2} display="flex" gridGap="8px" flexWrap="wrap">
                        {ticketData.entertainmentType.map((elm: any) => <span className={classes.CategorySpan}>{elm}</span>)}
                      </Box>
                      <Typography variant="body2" className={classes.Font500Weight}>{ticketData.language}</Typography>

                      <Box mt={1.5} display="flex" alignItems="center">
                        <PlaceOutlined className={classes.LocationIcon} />
                        <span className={classes.TheaterName}>
                          {ticketData.place_Name}
                        </span>
                      </Box>
                    </Box>
                  </Box>

                  <Box className={classes.TimingDetails}>
                    <Box display={{ xs: "none", sm: "block" }} className={classes.DisplayFlexCaption}>
                      <Typography variant="caption" className={classes.CaptionText}>
                        Get M-Ticket
                      </Typography>
                      <Switch
                        data-test-id="m-ticket-button"
                        checked={props.mTicketEnabled}
                        className={classes.CustomSwitch}
                        onChange={props.handleChangeMTicket}
                      />
                    </Box>
                  </Box>

                </Box>

                <Box className={classes.TicketSecondPart}>
                  <Box className={classes.ItoSecondPartWrapper}>

                    <Box className={classes.SectionDetails}>
                      <Box>
                        <Typography variant="body1" >{moment(ticketData.date[0]).format("ddd DD MMM,  YYYY")}</Typography>
                        <Typography variant="h6">{ticketData.date[1]}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1">{ticketData.type === BookingType.Movie ? "SCREEN" : "Class"}</Typography>
                        <Typography variant="h6">{ticketData?.screen_Name}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1">{convertToPad(ticketData?.number_Of_Seats)} Tickets</Typography>
                        {ticketData.type === BookingType.Movie && <Typography variant="h6" className={classes.SeatTierIto}>{ticketData?.seat_tier}</Typography>}
                      </Box>
                    </Box>

                    <Box>
                      <Divider className={classes.DottedDivider} />
                      <Typography variant="body2" className={classes.DisplayFlex}><span>Total Amount</span><span className={classes.Price}><span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>{Number(ticketData?.total_amount).toFixed(2)}</span></Typography>
                      <Divider className={classes.DottedDivider} />
                      {/* {props.isForEvent === "movie" && <Button fullWidth className={classes.SubmitButton} data-test-id="listbutton" onClick={props.handleList}>List to Sell</Button>} */}
                    </Box>
                  </Box>
                </Box>

                <Box className={classes.ITODetailsWrapper}>

                    <Box className={classes.SectionDetails}>
                      <Box>
                        <Typography variant="body1" >{moment(ticketData.date[0]).format("ddd DD MMM,  YYYY")}</Typography>
                        <Typography variant="h6">{ticketData.date[1]}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1">{ticketData.type === BookingType.Movie ? "SCREEN" : "Class"}</Typography>
                        <Typography variant="h6">{ticketData?.screen_Name}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1">{convertToPad(ticketData?.number_Of_Seats)} Tickets</Typography>
                        {ticketData.type === BookingType.Movie && <Typography variant="h6" className={classes.SeatTierIto}>{ticketData?.seat_tier}</Typography>}
                      </Box>
                    </Box>

                    <Box>
                      <Divider className={classes.DottedDivider} />
                      <Typography variant="body2" className={classes.DisplayFlex}><span>Total Amount</span><span className={classes.Price}><span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>{Number(ticketData?.total_amount).toFixed(2)}</span></Typography>
                      <Divider className={classes.DottedDivider} />
                    </Box>
                  </Box>
              </Box>
            </Paper>
          </Container> :
          <Container className={classes.OverFlow}>
            <Box className={classes.TitleWrapper} textAlign="center">
              <CheckCircleOutlined className={classes.CheckIcon} />
              <Typography align="center" variant="h2" className={classes.Title} data-test-id="booking" >Booking Successful!</Typography>
            </Box>
            <Paper elevation={0} className={classes.TicketWrapper}>


              <Box className={classes.ContainerWrapper}>

                <Box className={classes.TicketFirstPart}>
                  <div className={classes.Line}></div>
                  <div className={classes.TopElement}></div>
                  <div className={classes.BottomElement}></div>

                  <Box className={classes.PosterDetailsWrapper}>
                    <Box>
                      <img className={classes.PosterImage} src={ticketData.image_url} alt="Movie Poster" />
                    </Box>
                    
                    <Box>
                      <Typography variant="subtitle1" className={classes.MovieTitle}>{ticketData.name}</Typography>

                      <Box className={classes.CategoryWrapper}>
                        {ticketData.entertainmentType.map((elm: any) => <span className={classes.CategorySpan}>{elm}</span>)}
                      </Box>

                      <Typography variant="body2" className={classes.Font500Weight}>{ticketData.language}</Typography>

                      <Box mt={1} display="flex" alignItems="center">
                        <PlaceOutlined className={classes.LocationIcon} />
                        <span className={classes.TheaterName}>
                          {ticketData.place_Name}
                        </span>
                      </Box>

                    </Box>

                  </Box>

                  <Box className={classes.TimingDetails}>
                    <Grid container spacing={1}>

                      <Grid item xs={12} sm={4} className={classes.Margin4Mui}>
                        <Typography variant="body1">{moment(ticketData.date[0]).format("ddd DD MMM,  YYYY")}</Typography>
                        <Typography variant="h6">{ticketData.date[1]}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={3} className={classes.Margin4Mui}>
                        <Typography variant="body1">{ticketData.type === BookingType.Movie ? "SCREEN" : "Class"}</Typography>
                        <Typography variant="h6">{ticketData?.screen_Name}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md className={classes.Margin4Mui}>
                        <Typography variant="body1">{convertToPad(ticketData?.number_Of_Seats)} Tickets</Typography>

                        {ticketData.seat_allocation_type !== "manual"
                          && ticketData?.seat_id_number && <Box className={classes.SeatWrapper}>
                            {ticketData?.seat_id_number.map((seat: any) => (
                              <Box key={seat.id}>
                                {props.isForEvent === "event" ? <EventSeat seatLabel={seat.seat_number} /> : <Seat seatLabel={seat.seat_number} />}
                              </Box>
                            ))}
                          </Box>}
                        </Grid>

                      {ticketData.seat_allocation_type === "manual" &&
                        <Typography variant="body1">Seats will be assigned on a first-come, first-served basis. </Typography>
                      }
                      <Grid item xs={12} className={classes.DisplayFlexCaption}>
                        <Typography variant="caption" className={classes.CaptionText}>
                          Get M-Ticket
                        </Typography>
                        <Switch
                          data-test-id="m-ticket-button"
                          checked={props.mTicketEnabled}
                          className={classes.CustomSwitch}
                          onChange={props.handleChangeMTicket}
                        />
                      </Grid>

                    </Grid>

                    <Box className={classes.MobileQRWrapper}>
                      <Box className={classes.QRWrapper} mb={1}>
                        <img className={classes.QRCode} src={ticketData?.qrCode} alt="QR Code" />
                      </Box>
                      <Typography align="center" className={classes.QRText}>Booking Id</Typography>
                      <Typography align="center" className={classes.QRId}>{ticketData?.uniq_booking_id}</Typography>
                    </Box>

                  </Box>

                  <Box display={{ xs: "block", sm: "none" }} mt={2}>
                    <Divider className={classes.DottedDivider} />
                    <Typography variant="body2" className={classes.DisplayFlex}><span>Total Amount</span><span className={classes.Price}><span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>{Number(ticketData?.total_amount).toFixed(2)}</span></Typography>
                    <Divider className={classes.DottedDivider} />
                  </Box>

                </Box>

                <Box className={classes.TicketSecondPart}>
                  <Box className={classes.QRWrapper} mb={2}>
                    <img className={classes.QRCode} src={ticketData?.qrCode} alt="QR Code" />
                  </Box>
                  <Typography align="center" className={classes.QRText}>Booking Id</Typography>
                  <Typography align="center" className={classes.QRId}>{ticketData?.uniq_booking_id}</Typography>
                  <Box mb={1} display="flex" justifyContent="center" alignItems="center">
                    <Box className={classes.PriceWrapper}>
                      <Divider className={classes.DottedDivider} />
                      <Typography variant="body2" className={classes.DisplayFlex}><span>Total Amount</span><span className={classes.Price}><span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>{Number(ticketData?.total_amount).toFixed(2)}</span></Typography>
                      <Divider className={classes.DottedDivider} />
                      <Button disabled={ticketData.seat_allocation_type === "manual"} fullWidth className={classes.SubmitButton} data-test-id="listbutton" onClick={props.isForEvent === "event" ? props.handleListEvent : props.handleList}>List to Sell</Button>
                    </Box>
                  </Box>
                </Box>
              </Box>

            </Paper>

            <Box className={classes.MobileFooter}>
              <Button disabled={ticketData.seat_allocation_type === "manual"} fullWidth className={classes.SubmitButton} onClick={props.isForEvent === "event" ? props.handleListEvent : props.handleList}>List to Sell</Button>
            </Box>
          </Container>
      }

    </Box>
  )
}

interface SeatProps {
  seatStatus?: SeatStatus,
  seatLabel: string,
}

export const Seat: React.FC<SeatProps> = ({ seatLabel, seatStatus = SeatStatus.Selected }) => {

  const classes = useStyles()

  const classNameBasedOnSeatStatus = () => {

    switch (seatStatus) {
      case SeatStatus.Selected:
        return 'selected'

      case SeatStatus.Unavailable:
        return 'unavailable'

      case SeatStatus.Buy:
        return 'buy'

      default:
        return ''
    }

  }

  return (
    <Box display="inline-block">
      <Box className={`${classes.Seat} ${classNameBasedOnSeatStatus()}`}>
        {seatLabel}
      </Box>
    </Box>
  )
}

export const EventSeat: React.FC<SeatProps> = ({ seatLabel}) => {

  const classes = useStyles()
  return (
    <Box display="inline-block">
      <Box className={classes.EventSeat}>
        {seatLabel}
      </Box>
    </Box>
  )
}

export default BookingSuccessfulTicket;
// Customizable Area End
