import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AboutBannerApiResponse, AboutBannerData, AboutUsApiResponse, GalleryItem } from "../../../components/src/interfaces.web";
import { callApi } from "../../../components/src/Toolkit";
import { ContentSection } from "../../../components/src/enums.web";

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  investors: GalleryItem[];
  team: GalleryItem[];
  showFullText:boolean;
  isHovering:boolean;
  isLoading: boolean;
  bannerList: AboutBannerData[];
  sections: {
    aboutUs: string;
    ourTeam: string;
    futurePlans: string;
    investors: string;
  }
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AboutUsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAboutUsInfoApiCallId: string = "";
  getInvesterInfoApiCallId: string = "";
  getTeamInfoApiCallId: string = "";
  getAboutUsBannerApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      investors: [],
      team: [],
      showFullText:false,
      isHovering:false,
      isLoading: true,
      bannerList: [],
      sections: {
        aboutUs: "",
        futurePlans: "",
        ourTeam: "",
        investors: ""
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
     if (this.getAboutUsInfoApiCallId=== message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiResponse) {

        const { data } = (apiResponse as AboutUsApiResponse);

        const aboutUs = data.find(item => item.attributes.section_type === ContentSection.AboutUs)?.attributes.description;
        const ourTeam = data.find(item => item.attributes.section_type === ContentSection.Team)?.attributes.description;
        const futurePlans = data.find(item => item.attributes.section_type === ContentSection.Future)?.attributes.description;
        const investors = data.find(item => item.attributes.section_type === ContentSection.Investors)?.attributes.description;

        this.setState({ 
          isLoading: false,
          sections: {
            aboutUs: aboutUs || "",
            ourTeam: ourTeam || "",
            futurePlans: futurePlans || "",
            investors: investors || ""
          }
        })
      }
    }

if (this.getInvesterInfoApiCallId=== message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
  const apiResponse = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  
  if (apiResponse) {
    this.setState({ investors: apiResponse.data })
   }

  }

  if (this.getTeamInfoApiCallId=== message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiResponse) {
      this.setState({ team: apiResponse.data })
     }

    }

    if(this.getAboutUsBannerApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if(apiResponse) {
        this.setState({ bannerList: (apiResponse as AboutBannerApiResponse).data })
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    window.scrollTo(0, 0)
    this.getAboutUsInfo()
    this.getInvestorInfo()
    this.getTeamInfo()
    this.getAboutUsBanner()
    
   
 // Customizable Area End
  }

  // Customizable Area Start
  getAboutUsBanner = () => {
    this.getAboutUsBannerApiCallId = callApi({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: configJSON.aboutUsBannerApiEndPoint,
      headers: { "token": configJSON.guestToken },
    }, runEngine);
  }
        getAboutUsInfo = () => {

          const header = {
            "Content-Type": configJSON.contentTypeApi,
            "token": configJSON.guestToken 
           };
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.getAboutUsInfoApiCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.aboutUsInfoApiCallUrl
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiGetMethodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
         }

        getTeamInfo = () => {

          const header = {
            "Content-Type": configJSON.contentTypeApi,
            };
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.getTeamInfoApiCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.teamInfoApiCallUrl
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiGetMethodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
         }
    

        getInvestorInfo = () => {

          const header = {
            "Content-Type": configJSON.contentTypeApi,
            };
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.getInvesterInfoApiCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.investorInfoApiCallUrl
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiGetMethodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
         }
    

 handleBtnClick = () => {
    this.setState({showFullText:!this.state.showFullText});
  };

  handleTeamBtn=()=>{
    this.props.navigation.navigate('AboutTeam')
  }
  handleInvestorBtn=()=>{
    this.props.navigation.navigate('AboutInvestor')
  }

  
  // Customizable Area End
}
