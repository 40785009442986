import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { EventListenerKeys, LocalStorageKeys } from "../../../components/src/enums.web";
import { IUserDetails } from "../../../components/src/interfaces.web";
import DigioFunction from "../../../components/src/Digio.web";

// Customizable Area Start
import CfWalletAdapter from "../../adapters/src/CfWalletAdapter";

const walletAdapterObj = new CfWalletAdapter();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleOnClose:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class KycDialogeBoxController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    getDigioTokenApiCallId: string = "";
    kycVerifiedSuccessful : string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start

            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            
          if (resJson) {
            switch (apiCallId) {
              case this.getDigioTokenApiCallId:
                this.handleDigioRedirect(resJson);
                break;
        
              case this.kycVerifiedSuccessful:
                window.dispatchEvent(new Event(EventListenerKeys.ProfileUpdated))
                this.navigateToWallet();
                break;
            }
          }
      
          this.setState({ loading: false });
          }
        // Customizable Area End
    }

    // Customizable Area Start

    handleKYCVerification = async () => {
      const { attributes: { email, full_phone_number } }: IUserDetails = await getStorageData(LocalStorageKeys.UserDetails, true);
      const body = {
        "customer_identifier": email ?? full_phone_number,
        "customer_name": "rahul",
        "actions": [
          {
            "type": "DIGILOCKER",
            "title": "Digilocker KYC",
            "description": "Please share your aadhaar card and Pan from digilocker",
            "document_types": ["AADHAAR"]
  
          }
        ],
        "notify_customer": true,
        "generate_access_token": true
      }
  
      this.getDigioTokenApiCallId = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.PostMethod,
        endPoint: configJSON.getDigioToken,
        body: body,
      }, runEngine)
    }

    handleDigioRedirect = async (responseJson: any) => {
      const { attributes: { email, full_phone_number } }: IUserDetails = await getStorageData(LocalStorageKeys.UserDetails, true)
      DigioFunction(
        this.kycVerificationCompleted,
        responseJson.access_token?.entity_id,
        responseJson.access_token?.id,
        email ?? full_phone_number,
        full_phone_number
      )
      this.props.handleOnClose();
    }

    // to update the Kyc Status
  kycVerificationCompleted = async() => {
    const getUserDetails: IUserDetails = await getStorageData(LocalStorageKeys.UserDetails, true);
    getUserDetails.attributes.kyc_status = "approved"
    setStorageData(LocalStorageKeys.UserDetails, JSON.stringify(getUserDetails))
    const data = new FormData();
    data.append("data[kyc_status]", "approved");
    const usertoken = await getStorageData(LocalStorageKeys.LoginToken)
    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.kycVerifiedSuccessful = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ "token": usertoken || "guest_user" })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountDetailsUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToWallet = () => {
    const message = new Message(getName(MessageEnum.NavigationWallet))
    message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    walletAdapterObj.send(message)
  }
    // Customizable Area End
}
