Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Events";
exports.labelBodyText = "Events Body";
exports.acceptRequestEndPoint = "/events/requests/accepted";
exports.createEventEndPoint = "/events/events";
exports.GOOGLE_API_KEY = "AIzaSyAQu429K52drmir9450TvUYcy82ZL3QQ9I";
exports.GOOGLE_PACES_API_BASE_URL = "https://maps.googleapis.com/maps/api/place";

exports.labelHowItWork = "This is how it work"

// Api End points
exports.eventRegionEndPoint="bx_block_location/user_cities/all_regions";
exports.eventCityEndPoint="bx_block_location/user_cities"
exports.eventTypeEndPoint="bx_block_events/events/event_types"
exports.expectedAudienceEndPoint="bx_block_events/events/event_ranges"
exports.eventDateEndPoint="bx_block_events/events/event_date_ranges"
exports.createEventEndPoint="bx_block_events/events"
exports.getAllEventEndPoint="bx_block_events/events"
exports.getEventVenueListEndPoint = "bx_block_events/event_venues"
exports.getITOEventVenueListEndPoint = "bx_block_events/event_itos/event_venues"
exports.getEventTierListEndPoint = "bx_block_events/event_venues/event_seats?event_schedule_id="
exports.eventBookingEndPoint ="bx_block_events/event_bookings"
exports.eventsItoBookingEndPoint ="bx_block_events/event_itos/create_booking"
exports.getEventBookingDataApiEndPoint="bx_block_events/event_bookings"
exports.getListedTicketApiEndPoint="bx_block_events/event_booking_requests?schedule_id="
exports.resellEventBookingEndPoint ="bx_block_events/event_booking_requests/bookingId/resell_event_booking"
exports.TermsAndConditionsURL ="bx_block_events/events/terms_and_conditions"
exports.getMovieEventItoListing = "bx_block_categories/itos/ito_listing";
exports.getLanguageEndPoint="bx_block_events/events/event_languages";
exports.getGenersEndPoint="bx_block_events/events/event_show_types";
exports.guestToken = "guest_user";
exports.eventLayout = "Event Layout";

// Api Type
exports.PostMethod = "POST";
exports.GetMethod = "GET";
exports.DeleteMethod = "DELETE";
exports.contentTypeApi = "application/json";
exports.PutMethod = "PUT";

exports.heading = "Event Screen"
exports.tierList = [
  {
    name:"Tier: Early Bird Entry",
    time:" 800.00"
  },
  {
    name:"Tier: Gold Seating Zone",
    time:" 1000.00"
  },
  {
    name:"Tier: Gold Seating Zone",
    time:" 1000.00"
  },
  {
    name:"Tier: Early Bird Entry",
    time:" 1000.00"
  },
  {
    name:"Tier: Early Bird Entry",
    time:" 1000.00"
  },
  {
    name:"Tier: Gold Seating Zone",
    time:" 1000.00"
  },
]

exports.seatNumber = [
  {type:"Solo",number:1},
  {type:"Twin",number:2},
  {type:"Trio",number:3},
  {type:"Band",number:4},
  {type:"Unit",number:5},
  {type:"Crew",number:6},
  {type:"Gang",number:7},
  {type:"Club",number:8},
  {type:"Team",number:9},
  {type:"Army",number:10},
]

exports.listedTicketList = [
  {
    name:"Tier: Gold",
    time:" 600.00",
    seat:["A1"],
    numberOfSeats:"01"
  },
  {
    name:"Tier: Gold",
    time:" 2400.00",
    seat:["A1", "A2", "A3", "A4"],
    numberOfSeats:"04"
  },
  {
    name:"Tier: Gold",
    time:" 600.00",
    seat:["A1"],
    numberOfSeats:"01"
  },
]

exports.Done="Done"
exports.GoRegularBooking = "No Tickets Listed!"
exports.noTicketMessage="No Tickets Listed!"
exports.RatingTitle = "Rating";
exports.TrailerTitle = "Play Trailer";
exports.ApplyForIto = "Apply for ITO";
exports.ITOMessage = "No Movies or Events are listed for this location at the moment. Explore other locations or come back soon for updates!";

// Customizable Area End
