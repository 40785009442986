
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  ToLogin: any;
  showSignup: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isRegistration: boolean;
  modelOpen: boolean;
  isShowPassword: boolean;
  isShowConfirmPassword: boolean;
  isChecked: boolean;
  phone: any;
  mobileOtpVerify: boolean,
  emailOtpVerify: boolean
  sms_account: boolean,
  withGoggle: boolean,
  withEmail: boolean,
  logInwith: boolean,
  LognInType: string,
  passwordLoginValue: any,
  rememberMe: boolean,
  activeButton: string,
  emailOtpScreen: boolean,
  withPassword: boolean,
  forgotPass: boolean,
  mobileOtp: string,
  forgotOtp: string,
  minutes: any,
  seconds: any,
  otpError: string,
  email: string,
  emailError: boolean;
  emailErrorMsg:string,
  resetVaue: any,
  resetPassword: boolean,
  carasol: boolean,
  ToSignUp: boolean,
  passwordreset: boolean,
  loginModal: boolean,
  new_password: string,
  new_passwordError: string,
  confirm_password: string,
  confirmPasswordError: string
  // otpValue:any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class UserPasswordForgotController
  extends BlockComponent<Props, S, SS>{
  forgotPasswordCallId: any;
  resendCodeCallId: any;
  forgotOtpCallId: any;
  resetPasswordCallId: any
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
      // Customizable Area End
    ]);

    this.state = {
      // Customizable Area Start
      loading: false,
      isRegistration: false,
      modelOpen: true,
      isShowPassword: false,
      isShowConfirmPassword: false,
      isChecked: true,
      phone: null,
      mobileOtpVerify: false,
      emailOtpVerify: false,
      sms_account: false,
      withGoggle: false,
      withEmail: false,
      logInwith: true,
      LognInType: '',
      passwordLoginValue: null,
      rememberMe: false,
      activeButton: 'OTP',
      emailOtpScreen: false,
      withPassword: false,
      forgotPass: true,
      mobileOtp: '',
      forgotOtp: '',
      minutes: 1,
      seconds: 59,
      otpError: '',
      email: '',
      emailError: false,
      emailErrorMsg:"",
      resetVaue: null,
      resetPassword: false,
      carasol: true,
      ToSignUp: false,
      passwordreset: false,
      loginModal: false,
      new_password: '',
      new_passwordError: '',
      confirm_password: '',
      confirmPasswordError: ''
      // otpValue:null
      // Customizable Area End
    };
  }

  // Customizable Area Start
  //When facebook sends back the reponse this gets called
  handleToSignup = () => {
    this.setState({ ToSignUp: true })
  }

  handleClose = () => {
    this.setState({ modelOpen: false })
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    this.stopLoading();
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.forgotPasswordCallId) {
        this.handleReceive(resJson);
      }
      if(apiRequestCallId === this.resendCodeCallId){
        this.handleresend(resJson)
      }
      if (apiRequestCallId === this.forgotOtpCallId) {
        this.handleOtpConfirmation(resJson)
      }
      if (apiRequestCallId === this.resetPasswordCallId) {
        if (resJson && resJson.errors && resJson.errors.length) {
          const errors = resJson.errors[0].otp;
          this.setState({ otpError: errors, new_passwordError: resJson.errors[0].password  })      
        } else {
          this.setState({ passwordreset: true })

        }
      }

    } else {
      runEngine.debugLog("GOIT");
    }
  }
  // Customizable Area Start
  handleCloseModal = () => {
    this.setState({ passwordreset: false,loginModal: true })
  }

  handlePasswordValidation = () => {
    let passWordReq = "Password Required"

    if (this.state.new_password === "" || !this.state.new_password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/)) {
      this.setState({ new_passwordError: passWordReq });
    } else {
      this.setState({ new_passwordError: '' })
    }
  }

  handleresend =(resJson:any)=>{
    if (resJson && resJson?.errors && resJson?.errors?.length) {
      const errors = resJson.errors[0].otp;
      this.setState({ otpError: errors })
    }
  }

  handlePasswordValue = (value: any) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    const password = value;
    if (!passwordRegex.test(password)) {
      this.setState({ new_password: value , 
        new_passwordError: configJSON.errorsMessage})
    }else{
      this.setState({ new_password: value , 
        new_passwordError: ""})
   
    }
  }

  handleOtpConfirmation = (resJson: any) => {
    if (resJson && resJson?.errors && resJson?.errors?.length) {
      const errors = resJson.errors[0].otp;
      this.setState({ otpError: errors })
    } else {
      this.setState({ emailOtpVerify: false, resetPassword: true })
    }
  }
  handleReceive = (resJson: any) => {

    if(resJson.errors){
      this.setState({ emailError: true, emailErrorMsg:resJson.errors[0].otp})
    }else{
      const token = resJson.meta.token;
      localStorage.setItem('forgotlogin', token);
      this.setState({ seconds: 59, minutes: 1, forgotOtp: '', forgotPass: false, emailOtpVerify: true, otpError: "" });
      this.handleTime()
    }
  }

  // Customizable Area Start

  //Create response callback.


  showPasswordChange = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };

  showConfirmPassword = () => {
    this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword });
  };

  validateConfirmPassword = () => {
    if (this.state.confirm_password === "" || this.state.confirm_password !== this.state.new_password) {
      this.setState({ confirmPasswordError: "Password does not match" });
    } else {
      this.setState({ confirmPasswordError: '' })
    }
  }

  handleConPasswordValue = (value: any) => {
    this.setState({ confirm_password: value })
  }

  handleCheckChange = () => {
    this.setState({ isChecked: !this.state.isChecked })
  }

  handlePhoneChange = (value: any) => {
    this.setState({ phone: value })
  }

  handleEmail = (value: any) => {
    this.setState({ email: value, emailError: false })
  }
  handleResetValue = (value: any) => {
    if (value !== null) {
      this.setState({ resetVaue: value })
    }
  }

  handleForgotOtpValue = (value: any) => {
    const onlyNumbers = /^\d*$/;
    if (onlyNumbers.test(value) || value === '') {
        this.setState({ forgotOtp: value, otpError: '' });
    } else {
        this.setState({ otpError: 'Please enter only numbers.' });
    }
}

  handleLoginWith = (isSelected: any) => {
    if (isSelected === "sms_account") {
      this.setState({ sms_account: true, logInwith: false })
    }
    if (isSelected === "OTP") {
      this.setState({ activeButton: "OTP", withPassword: false, logInwith: false, emailOtpScreen: true })
    }
    if (isSelected === "Password") {
      this.setState({ activeButton: "Password", withPassword: true, emailOtpScreen: false, logInwith: false })
    }
    if (isSelected === "forgotPass") {
      this.setState({ forgotPass: true, withPassword: false })
    }
    this.setState({ LognInType: isSelected })
  }
  handleTime = () => {
    const interval = setInterval(() => {
      const { minutes, seconds } = this.state;

      if (seconds > 0) {
        this.setState({ seconds: seconds - 1 })
      } else {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          this.setState({ seconds: 59, minutes: minutes - 1 });
        }
      }
    }, 1000)
  }

  handleLogIn = () => {
    if (this.state.email === '' || !this.state.email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
      this.setState({ emailError: true, emailErrorMsg:"Enter a valid Email id" })
    } else if (this.state.isChecked === false) {
      return
    } else {
      this.forgotPassword()
    }

  }


  forgotPassword = () => {
    const header = {
      "Content-Type": 'application/json',
    };
    const httpBody = {
      "data":
      {
        "attributes":
        {
          "email": this.state.email
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forgotPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.forgotPasswordCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleResendOtp = () => {
    const header = {
      "Content-Type": 'application/json',
    };
    const httpBody = {
      data: {
        "type": "email_account",
        "attributes": {
          "email": this.state.email,
          "email_otp":this.state.forgotOtp
        }
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendCodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendCodeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.forgotPasswordCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleEmailValidate = () => {
    const EmailRegExr = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (this.state.email === "" || !this.state.email.match(EmailRegExr)) {
      this.setState({ emailError: true });
    } else {
      this.setState({ emailError: false });
    }
  }


  handleForgotOTP = () => {
    if (this.state.forgotOtp === '') {
      this.setState({ otpError: 'please enter otp' })
    } else {
      const token = localStorage.getItem('forgotlogin')
      const header = {
        "Content-Type": 'application/json',
      };
      const httpBody = {
        "data": {
          "token": token,
          "otp_code": this.state.forgotOtp
        }
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.forgotOtpCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.forgotOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.forgotPasswordCallMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  handleResetPassword = () => {
    let confirm_passworderror = "Password does not match"
    let new_passworderror ="Password Required"
    if (this.state.confirm_password === "" || this.state.confirm_password !== this.state.new_password ) {
      this.setState({ confirmPasswordError:confirm_passworderror,new_passwordError:new_passworderror });
    } else {
      if(!this.state.emailError){
      this.setState({ confirmPasswordError: "", new_passwordError: "" });
      const token = localStorage.getItem('forgotlogin')
      const header = {
        "Content-Type": 'application/json',
      };
      const httpBody = {
        "data": {
          "token": token,
          "new_password": this.state.new_password || '',
          "confirm_password": this.state.confirm_password || ''

        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.resetPasswordCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.resetPasswordEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.forgotPasswordCallMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }
  }
  // Customizable Area End
}
