import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    Container,
    Paper,
    Typography,
   } from "@material-ui/core";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import PrivactPolicyController, {Props} from "./PrivacyPolicyController.web";
import Loader from "../../../components/src/Loader";

export const configJSON = require("./config");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
  
    typography:{
      h1:{
          fontFamily: 'Poppins',
          fontSize: '22px',
          fontWeight: 600,
          color: '#ffffff',
          margin: "20px 0 30px",
          padding:"0 40px",
          '@media(max-width:700px)': {
              fontSize: "18px",
              padding:"0 10px",
          }
      },
      h2:{
          fontFamily: 'Poppins',
          fontSize: '16px',
          color: '#ffffff',
          margin: 0,
          padding:"0 40px",
          '@media(max-width:700px)': {
              fontSize: "14px",
              padding:"0 10px",
          }
      },
    },
  });
  

export default class PrivacyPolicy extends PrivactPolicyController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
              <Loader loading={this.state.loading}/>
        <Container maxWidth={false} style={webStyle.paddingLeft}>
          <Paper elevation={0} style={webStyle.mainPaperStyle}>
            {/* ----------------------------App Bar---------------------------------------------------- */}
              <MainHeaderComp navigation = {this.props.navigation} />
              <div style={webStyle.container}>

                  <Typography variant="h1">{configJSON.PrivacyPolicyLabel}</Typography>
                  <Typography align="justify" variant="h2" dangerouslySetInnerHTML={{ __html: this.state.description }}></Typography>

              </div>
              <FooterWithEvents />
              </Paper>
              </Container>
              </ThemeProvider>
        );
    }
}

// Customizable Area Start

const webStyle = {
    mainPaperStyle: {
      minHeight: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column' as const,
      backgroundColor: "#121212",
    },
    paddingLeft:{
      paddingLeft:0
    },
    container:{
      width:"100%",
      maxWidth:"1280px",
      marginLeft:"auto",
      marginRight:"auto",
  },
}
// Customizable Area End