import React from "react";

import {
    // Customizable Area Start
    Box,
    Button,
    Collapse,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Radio,
    RadioGroup,
    Typography,
    styled,
     createStyles,
     Fade,
     CircularProgress,
     Backdrop
    // Customizable Area End
} from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h4:{
          fontSize:16,
          fontWeight:"normal",
          lineHeight:"normal"  
        },
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

});
const SeatTypeData = styled(Box)({
    textTransform: 'capitalize',
    fontFamily: "Poppins",
    textAlign: 'center'
});
const CustomRadio = withStyles({
    root: {
        color: '#fff',
        '&$checked': {
            color: '#ffb43a',
        },
    },
    checked: { fontWeight: 'bold', },
})((props) => <Radio color="primary" {...props} />);
import { backarrowleft, close, location, MovieImage, ticketStar, cancelledImage, qr_code } from "./assets";
import { Seat } from "../../../components/src/BookingSuccessfulTicket.web";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { alertImage, SeatIcon } from "../../reservations2/src/assets";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import moment from "moment";
import MapDialog from "../../../components/src/MapDialog";
import Loader from "../../../components/src/Loader.web";
import { convertToPad, goToPrevPage } from "../../../components/src/utilities";
import RFHDialog from "../../../components/src/RFHDialog";

const RFHButton = styled(Button)({
    fontFamily: "Poppins, Roboto",
    textTransform: "capitalize",
    color: "#000",
    borderRadius: "12px",
    backgroundColor: "#ffb43a",
    padding: "8px",
    fontWeight: 500,
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#ffb43a"
    },
    "@media (max-width: 600px)": {
        fontSize: "14px",
        borderRadius: "8px"
    }
  })

const configJSON = require("./config");
// Customizable Area End

import CancelBookingController, {
    Props,
} from "./CancelBookingController.web";




class CancelBooking extends CancelBookingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    movieDetails = (classes: any, bookingAttributes: any, dateArray: any) => {
        const { isCancelled } = this.state
        return (
            <Box className={classes.cancelBg} >
                <Box className={classes.mainWrappers}>
                    <Box className={classes.ImageWrapper} >
                        <img src={bookingAttributes?.movie.image_url} alt="" className={classes.MovieImage} />
                    </Box>
                    <Box className={classes.MovieWrappers}>
                        <Typography component={'p'} className={classes.wrapperTypo}>{bookingAttributes?.movie.name}</Typography>
                        <Typography component={'p'}>{bookingAttributes?.language}, {bookingAttributes?.screen_type} </Typography>

                        <Box className={classes.fontSizeWrapper}>
                            <span>{moment(dateArray && dateArray[0]).format("ddd DD MMM,  YYYY")}
                                <span style={{ color: '#ffb43a' }}> | </span>
                                {dateArray && dateArray[1]}
                            </span>
                        </Box>
                        <Typography component={'p'}> {bookingAttributes?.theatre_name}</Typography>
                        <Typography component={'p'} className={classes.releaseDate}>  Release Date : {moment(bookingAttributes?.movie.release_date).format("DD MMM,  YYYY")}</Typography>
                        <Box className={classes.FindCinemaWrapper} onClick={this.handleOpenNavigation}>
                            <Icon>
                                <img src={location} className={classes.IconWrapper} />
                            </Icon>
                            <span className={classes.TheaterNames}> Find Cinema</span>
                        </Box>
                    </Box>
                </Box>
                {isCancelled ?
                    ''
                    :
                    <Box style={{ display: 'flex', gridGap: '25px' }}>
                        <Box style={{ width: '45%' }}>
                            <Box>
                                <Divider className={classes.Divider} />
                            </Box>
                            <Box className={classes.wrapperIcon}>
                                <Box className={classes.IconWrapper}>
                                    <img src={ticketStar} alt="Seat" className={classes.TicketIcon} />
                                </Box>
                                <SeatTypeData>{bookingAttributes?.seat_tier[0]}</SeatTypeData>
                            </Box>
                            <Box >
                                <Divider className={classes.Divider} />
                            </Box>
                        </Box>
                        <Box style={{ width: '45%' }}>
                            <Box>
                                <Divider className={classes.Divider} />
                            </Box>
                            <Box className={classes.wrapperIcon}>
                                <Box className={classes.IconWrapper}>
                                    <img src={SeatIcon} alt="Seat" className={classes.TicketIcon} />
                                </Box>
                                <SeatTypeData>No of Seats {bookingAttributes?.number_of_seats.toLocaleString('en-US', { minimumIntegerDigits: 2 })}</SeatTypeData>
                            </Box>
                            <Box >
                                <Divider className={classes.Divider} />
                            </Box>
                        </Box>
                    </Box>
                }
            </Box>
        )
    }

    listContainer = (classes: any, isCostOpen: any, bookingAttributes:any) => {
        return (
            <List className={classes.PaymentWrapper}>
                <ListItem className={classes.PaymentListItem}>
                    <ListItemText className={classes.MenuListText}>
                        Total Refund Amount
                        <ListItemIcon className={classes.MenuExpandIcon} data-test-id="totalCost-dropdown" onClick={() => this.showCost()}>
                            {isCostOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                    </ListItemText>
                    <span>
                        <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                        {bookingAttributes?.refund_amount && convertToPad(bookingAttributes?.refund_amount)}
                    </span>
                </ListItem>
                <Collapse data-test-id="cost-collapse" in={isCostOpen} timeout="auto" unmountOnExit>
                    <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}> Total Ticket Price</ListItemText>
                        <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                        {bookingAttributes?.sub_total}
                    </ListItem>
                    <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}>Cancellation Fee @ {bookingAttributes?.deduction_percent}%</ListItemText>
                        -<span><span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                        {bookingAttributes?.deduction_amount}
                        </span>
                    </ListItem>
                </Collapse>

            </List>
        )
    }
    renderContent() {
        const { classes } = this.props;
        const { isPolicy } = this.state;

        if (!isPolicy) {
            return (
                <Box className={classes.isContent}>
                    <Box>
                        <img src={alertImage} className={classes.imageRefund} alt="Alert" />
                    </Box>
                    <Typography style={{textAlign:"center"}} className={classes.wrapperTypo}>
                        {this.state.popupDescription}
                    </Typography>
                </Box>
            );
        } else {
            return (
                <Box>
                    <Box className={classes.cancelWraapper}>
                        <Typography className={classes.wrapperTypo}>Cancellation Policy</Typography>
                    </Box>
                    <Divider className={classes.Dividers} />
                    <Box>
                        <Box>
                            <Typography
                                variant="h4"
                                dangerouslySetInnerHTML={{
                                    __html:this.state.cancelBookingPolicy}}
                            />
                        </Box>
                    </Box>
                </Box>
            );
        }
    }
    renderCancelLimitMessage() {
        const { isCancelled, popupDescription } = this.state;

        if (isCancelled) {
            return (
                popupDescription
            );
        } else {
            return 'Select Refund Method!';
        }
    }
    renderButton() {
        const { classes } = this.props;
        const { isPolicy, cancelLimit } = this.state;

        if (!isPolicy) {
            return (
                <Button onClick={()=> this.handleOnClosePopup()} className={classes.DialogBtn} data-test-id="closeModal">
                    {cancelLimit ? 'Okay' : 'Proceed'}
                </Button>
            );
        }

        return null;
    }

    renderCanceList = (bookingAttributesData: any) => {
        const { classes } = this.props;
        return (
            <Box>
                <Box style={{ position: 'relative' }}>
                    <Box display="flex" justifyContent="center" >
                        <img className={classes.QRwrapper} src={bookingAttributesData?.qr_code} />
                        <img className={classes.cancelImage} src={cancelledImage} />
                    </Box>
                    <Box>
                        <Typography className={classes.Booking} align="center">Booking Id</Typography>
                        <Typography className={classes.BookingID} align="center">{bookingAttributesData?.uniq_booking_id}</Typography>
                    </Box>
                </Box>
                <Box mt={2}>
                    <Box className={classes.seatWrappers}>
                        <span className={classes.fontSizeWrapper}>{bookingAttributesData?.screen_name}</span>
                        <Box display="flex" className={classes.SeatDisplays} >
                            {bookingAttributesData?.cancel_seats.map((seat: any) => (
                                <Seat seatLabel={seat.seat_number} />
                            ))}
                        </Box>
                    </Box>
                    <Box pt={1} className={classes.seatWrappers} >
                        <span className={classes.ScreenInfo}>Screen</span>
                        <span className={classes.ScreenInfo}> Seats : {bookingAttributesData?.seat_tier[0]}</span>
                    </Box>
                </Box>
                <Divider className={classes.Dividers} />
                <Box>
                    {this.renderTotalAmount()}
                </Box>
                <Divider className={classes.Divider} />
                <Box>
                    <Typography className={classes.refundWrapper}>
                        The amount will be refunded to your original payment source within 3-4 days.
                    </Typography>
                </Box>
            </Box>
        )
    }

    renderTotalAmount = () => {
        const { classes } = this.props;
        const bookingAttributesData = this.state.bookingData.attributes
        return(
            <List className={classes.PaymentWrapper}>
                        <ListItem className={classes.PaymentListItem}>
                            <ListItemText className={classes.MenuListText}>
                                Total Amount
                                <ListItemIcon className={classes.MenuExpandIcon} data-test-id="totalAmount-dropdown" onClick={() => this.showTotalAmount()}>
                                    {this.state.isTotalAmount ? <ExpandLess /> : <ExpandMore />}
                                </ListItemIcon>
                            </ListItemText>
                            <span>
                                <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                {bookingAttributesData?.total_amount}
                            </span>
                        </ListItem>
                        <Collapse data-test-id="cost-collapse" in={this.state.isTotalAmount} timeout="auto" unmountOnExit>
                            <ListItem className={classes.PaymentListItem}>
                                <ListItemText className={classes.MenuListText}> Price per Ticket</ListItemText>
                                 <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                 {convertToPad((+bookingAttributesData.sub_total)/bookingAttributesData.number_of_seats)}
                            </ListItem>
                            <ListItem className={classes.PaymentListItem}>
                                <ListItemText className={classes.MenuListText}>Sub - Total</ListItemText>
                                <span><span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>{bookingAttributesData?.sub_total}</span>
                            </ListItem>

                            <ListItem className={classes.PaymentListItem}>
                                <ListItemText className={classes.MenuListText}>
                                    Convenience Fees
                                    <ListItemIcon className={classes.MenuExpandIcon} data-test-id="cost-dropdown" onClick={() => this.showCostData()}>
                                        {this.state.isConvenienceFeeOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemIcon>
                                </ListItemText>
                                <span>
                                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                    {bookingAttributesData?.convenience_fees}
                                </span>
                            </ListItem>

                            <Collapse data-test-id="cost-collapse" in={this.state.isConvenienceFeeOpen} timeout="auto" unmountOnExit>
                                <List className={classes.SubList} component="div" disablePadding>
                                    <ListItem className={classes.viewList}>
                                        <ListItemText className={classes.MenuListText}>Base Amount</ListItemText>
                                        <span className={classes.MenuListBody}>
                                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                            {bookingAttributesData?.base_amount}
                                        </span>
                                    </ListItem>
                                    <ListItem className={classes.viewList}>
                                        <ListItemText className={classes.MenuListText}>
                                            {`CGST @ ${bookingAttributesData?.cgst_percent}%`}
                                        </ListItemText>
                                        <span className={classes.MenuListBody}>
                                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                            {bookingAttributesData?.cgst}
                                        </span>
                                    </ListItem>
                                    <ListItem className={classes.viewList}>
                                        <ListItemText className={classes.MenuListText}>
                                        {`SGST @ ${bookingAttributesData?.sgst_percent}%`}
                                        </ListItemText>
                                        <span className={classes.MenuListBody}>
                                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                            {bookingAttributesData?.sgst}
                                        </span>
                                    </ListItem>
                                </List>
                            </Collapse>

                            {
                                this.state.foodCostCalculation.totalFoodAmount ? (
                                    <>
                                        <ListItem className={classes.PaymentListItem}>
                                            <ListItemText className={classes.MenuListText}>
                                                Food and Beverages
                                                <ListItemIcon data-test-id="food-cost-dropdown" className={classes.MenuExpandIcon}  onClick={() => this.showFoodCostDetails()}>
                                                    {
                                                        this.state.isFoodOpen ? <ExpandLess /> : <ExpandMore />
                                                    }
                                                </ListItemIcon>
                                            </ListItemText>
                                            <span>
                                                <span className={classes.RupeesSymbol}>
                                                    {"\u20B9"}&nbsp;
                                                </span>
                                                {
                                                    this.state.foodCostCalculation.totalFoodAmount.toFixed(2)
                                                }
                                            </span>
                                        </ListItem>

                                        <Collapse data-test-id="food-cost-collapse-id" timeout="auto"  in={this.state.isFoodOpen} unmountOnExit>
                                            <List component="div" className={classes.SubList} disablePadding>
                                                {
                                                    this.state.foodCostCalculation.Food.map((foodItem) => (
                                                        <ListItem className={classes.viewList}>
                                                            <ListItemText className={classes.MenuListText}>
                                                            {`${foodItem.label} (Qty ${foodItem.quantity})`}
                                                            </ListItemText>
                                                            <span className={classes.MenuListBody}>
                                                                <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                                                {foodItem.price.toFixed(2)}
                                                            </span>
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        </Collapse>
                                    </>
                                ) : null
                            }

                            <ListItem className={classes.PaymentListItem}>
                                <ListItemText className={classes.MenuListText}>Discount</ListItemText>
                                <span><span className={classes.RupeesSymbol}>- {"\u20B9"}&nbsp;</span>00.00</span>
                            </ListItem>
                        </Collapse>

                    </List>
        )
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {/* <Loader loading={this.state.loading}/> */}

                <Backdrop style={webStyle.BackDrop} open={this.state.openBackdropLoadingOpen}>
                    <CircularProgress style={webStyle.CircularProgress} />
                </Backdrop>

                <Paper elevation={0} >
                    <MainHeaderComp navigation = {this.props.navigation} />
                    {
                        this.state.loading ? (
                            <Fade in={this.state.loading} unmountOnExit>
                                <Box display="flex" justifyContent="center" minHeight="200px">
                                    <CircularProgress className={classes.CircularProgress} />
                                </Box>
                            </Fade>
                        )
                            :
                    (<Box className={classes.WrapperBoxs}>
                        <Container>
                            <Box className={classes.wrapperHeads} data-test-id="viewHead">
                                <img src={backarrowleft} alt="back" className={classes.imageSize} style={{ cursor: 'pointer' }}
                                    data-test-id="backs" onClick={this.handleBack} />
                                <Typography component={'p'} data-test-id="head" className={classes.wrapperTypo} >View Ticket</Typography>
                            </Box>
                            {
                                this.state.bookingData.attributes && (
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={7} lg={7}>
                                    {this.movieDetails(classes, this.state.bookingData.attributes,this.state.bookingData.attributes?.show_date_time.split(" | ") )}
                                    {this.state.isCancelled ?
                                        ""
                                        :
                                        <Box className={classes.cancelPolicyWrapper}>
                                            <Typography className={classes.TicketMessage}>Cancellation & Refund Terms</Typography>
                                            <Box className={classes.cancelText}>
                                                Bookings can be canceled up to 30 minutes before showtime. Refund will be processed according
                                                to  <Typography component={'span'} className={classes.cancelPolicy} data-test-id="cancel-pop" onClick={this.handleCancelPolicyPopup}>Cancellation Policy</Typography>
                                            </Box>
                                        </Box>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <Box className={classes.cancelBg} >
                                        <Box className={classes.mainBoxWrapper}>
                                            <Typography component={'p'} className={classes.viewTypos}>  Booking Summary</Typography>
                                            {this.state.isCancelled ?
                                                this.renderCanceList(this.state.bookingData.attributes)
                                                :
                                                <Box>
                                                    <Box mt={2}>
                                                        <Box className={classes.seatWrapper}>
                                                            <span className={classes.fontSizeWrapper}> {this.state.bookingData.attributes?.screen_name}</span>
                                                            <Box display="flex" className={classes.SeatDisplay} >
                                                                {this.state.bookingData.attributes?.seat_id_number.map((seat: any) => (
                                                                    <Seat seatLabel={seat.seat_number} />
                                                                ))}
                                                            </Box>
                                                        </Box>
                                                        <Box pt={1} className={classes.seatWrapper} >
                                                            <span className={classes.ScreenInfo}>Screen</span>
                                                            <span className={classes.ScreenInfo}> Seats : {this.state.bookingData.attributes?.seat_tier[0]}</span>
                                                        </Box>
                                                    </Box>
                                                    <Divider className={classes.Dividers} />
                                                    <Box>
                                                        {this.renderTotalAmount()}
                                                        <Box mt={2}>
                                                            {this.listContainer(classes, this.state.isCostOpen, this.state.bookingData.attributes)}
                                                        </Box>
                                                    </Box>
                                                    <Divider className={classes.Divider} />
                                                    <Box>
                                                        <Typography className={classes.fontSizeWrapper}> Refund</Typography>
                                                        <Box className={classes.choosepaybox}>
                                                            <Typography > Please select a Refund Method.</Typography>
                                                            <RadioGroup
                                                                aria-label="Bank"
                                                                name="Bank"
                                                                value={this.state.selectedValue}
                                                                onChange={this.handleChange}
                                                                data-test-id="radio_button"
                                                            >
                                                                {this.state.refundType.map((fund: any) => (
                                                                    <FormControlLabel
                                                                        key={fund.value}
                                                                        value={fund.value}
                                                                        control={<CustomRadio />}
                                                                        label={
                                                                            <p
                                                                                dangerouslySetInnerHTML={{ __html: fund.label }}
                                                                                className={classes.radioContainer}
                                                                            />
                                                                        }
                                                                    />
                                                                ))}
                                                            </RadioGroup>
                                                        </Box>
                                                    </Box>
                                                    <Divider className={classes.Divider} />

                                                    <Box mt={2}>
                                                        <Box className={classes.seatWrapper}>
                                                            <Typography className={classes.fontSizeWrapper}>Final Refund Amount</Typography>
                                                            <Box>
                                                                <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                                                {this.state.bookingData.attributes?.refund_amount}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <Button className={classes.CancelBtn} data-test-id={'view'} onClick={this.handleOnClickCancelBooking}>Cancel Booking</Button>
                                                    </Box>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            )}
                        </Container>
                    </Box>
                    )}
                    <FooterWithEvents />
                </Paper>

                <Dialog
                    open={this.state.isModalOpen}
                    PaperProps={{
                        style: {
                            backgroundColor: "#404040",
                            color: "#fff",
                            padding: '12px',
                            borderRadius: '8px',
                            maxWidth: this.state.isPolicy ? 575 : 456,
                        }
                    }}
                >
                    {this.state.isPolicy && <Box style={{ display: "flex", justifyContent: "end" }}>
                        <img src={close} alt="closebutton"
                            color="primary"
                            data-test-id="closeModal"
                            className={classes.CloseIcons}
                            onClick={this.handleOnClosePopup}
                        />
                    </Box>}
                    <DialogContent>
                        {this.renderContent()}
                    </DialogContent>
                    <DialogActions>
                        {this.state.isCancelled ?
                            <Button 
                                className={classes.DialogBtn} 
                                data-test-id="closeModal"
                                onClick={()=> this.handleOnClosePopup()}
                            >
                                Done
                            </Button>
                            :
                            this.renderButton()
                        }
                    </DialogActions>
                </Dialog>

                {/* MAP DIALOG FOR NAVIGATION */}
                <MapDialog
                    data-test-id="map-dialog"
                    open={this.state.openNavigationDialog}
                    handleClose={this.handleDialogClose}
                    title={this.state.bookingData.attributes?.theatre_name || ''}
                    destinationAddress={this.state.bookingData.attributes?.theatre?.complete_address || ''}
                    fullWidth
                />

                <RFHDialog
                    data-test-id="cancel-booking-dialog"
                    hideCloseButton
                    open={this.state.openCancelBookingError}
                    maxWidth="sm"
                    disableEscapeKeyDown={true}
                    dialogActions={(
                        <RFHButton data-test-id="proceed-btn" fullWidth onClick={goToPrevPage}>
                            Okay
                        </RFHButton>
                    )}
                    dialogContent={(
                        <BoxWrapper>
                            <Typography className="error__message" data-test-id="error-message">
                                {this.state.cancelBookingErrorMessage}
                            </Typography>
                        </BoxWrapper>
                    )}
                    dialogTitle={(
                        <BoxWrapper display="flex" justifyContent="center">
                            <img src={alertImage} alt="alert" className="icon" />
                        </BoxWrapper>
                    )}
                />

                <RFHDialog
                    hideCloseButton
                    data-test-id="kyc-pending-dialog"
                    open={this.state.openPendingKycDialog}
                    disableEscapeKeyDown={true}
                    maxWidth="sm"
                    dialogActions={(
                        <RFHButton data-test-id="kyc-proceed-btn" fullWidth onClick={this.closePendingKycDialog}>
                            {configJSON.okayLabel}
                        </RFHButton>
                    )}
                    dialogContent={(
                        <BoxWrapper>
                            <Typography className="error__message" data-test-id="kyc-error-message">
                                {configJSON.completeKyc}
                            </Typography>
                        </BoxWrapper>
                    )}
                    dialogTitle={(
                        <BoxWrapper display="flex" justifyContent="center">
                            <img src={alertImage} alt="alert" className="icon" />
                        </BoxWrapper>
                    )}
                />

            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    BackDrop: {
        zIndex: 2000
    },
    CircularProgress: {
        color: "#ffb43a"
    },
    errorMessage: {
        fontWeight: 500,
        fontSize: "16px"
    }
}

const BoxWrapper = styled(Box)({
    "& .error__message": {
        fontSize: "16px",
        fontWeight: 500,
        textAlign: "center",
        padding: "0 14px",
        "@media (max-width: 600px)": {
            fontSize: "14px"
        }
    },
    "& .icon": {
        width: "28px",
        height: "28px"
    }
})

const styles = () => createStyles({
    refundWrapper: {
        margin: '24px',
        height: '16px',
        fontFamily: 'Poppins',
        fontSize: '16px'
    },
    viewList: {
        '&.MuiListItem-gutters': {
            paddingLeft: '0px',
            paddingBottom:0,
            paddingTop:0
        }
    },
    Booking: {
        color: "#fff",
        fontSize: "14px",
        fontFamily: "Poppins, sans-serif"
    },
    BookingID: {
        color: "#fff",
        fontSize: "14px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 500
    },
    QRwrapper: {
        marginTop: "18px",
        marginBottom: "16px",
        height: "80px",
        width: "80px",
        "@media (min-width: 600px)": {
            marginTop: "24px",
            height: "106px",
            width: "106px",
        }
    },
    cancelImage: {
        position: 'absolute',
        top: '-24px',
        marginTop: "18px",
        marginBottom: "16px",
        height: "80px",
        width: "80px",
        "@media (min-width: 600px)": {
            marginTop: "24px",
            height: "206px",
            width: "264px",
        }
    },
    cancelLimitWrapper: {
        display: 'flex',
        width: '375px'

    },
    cancelWraapper: {
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center'
    },
    imageRefund: {
        width: '48px',
        heigth: '48px',
    },
    isContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        gridGap: '35px',
        marginBottom: '11px'
    },
    DialogBtn: {
        width: '400px',
        height: '56px',
        backgroundColor: '#ffb43a',
        borderRadius: '12px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        color: '#202020',
        fontSize: '18px',
        textTransform: 'none',
        margin:"auto",
        '&:hover': {
            backgroundColor: '#ffb43a',
            color: '#202020',

        }
    },
    CloseIcons: {
        display: "flex",
        alignItems: "flex-end",
        padding: 5,
        height: '28px',
        width: '28px',
        cursor: 'pointer',
    },
    choosepaybox: {
        marginTop: '15px',
        marginBottom: '20px'
    },
    radioContainer: {
        color: 'white',
        fontFamily: 'Poppins',
        fontSize: '18px',
        marginLeft: '7.5px',
        width: '100%'
    },
    seatWrapper: {
        display: "flex",
        justifyContent: "space-between",

    },
    seatWrappers: {
        display: "flex",
        justifyContent: "space-between",
    },
    WrapperBoxs: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        fontSize: '18px',
        fontFamily: 'Poppins',
        color: '#fff',
        margin: '12px 9px 9px 0px',
    },
    imageSize: {
        width: '32px',
        height: '32px'
    },
    wrapperHeads: {
        display: 'flex',
        alignItems: 'center',
        gridGap: '15px',
        marginBottom: '22px',
        marginTop: '18px'
    },
    wrapperTypo: {
        fontSize: '24px',
        fontWeight: 600
    },
    cancelBg: {
        backgroundColor: 'rgba(64,64,64,0.4)',
        padding: '30px',
    },
    mainWrappers: {
        display: 'flex',
        gridGap: '5px'
    },
    MovieImage: {
        width: '100%',
        height: '100%',
    },
    ImageWrapper: {
        width: "179px",
        height: "229px",
        border: "solid 1px #ffb53a",
        marginRight: "18px",
        "@media (max-width: 560px)": {
            width: "130px",
            height: "174px",
        },
        "@media (max-width: 660px)": {
            width: "130px",
            height: "174px",
        }
    },
    MovieWrappers: {
        display: 'flex',
        flexDirection: 'column' as const,
        gridGap: '8px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    },
    fontSizeWrapper: {
        fontSize: '18px',
        fontWeight: 600
    },
    releaseDate: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: "#b4b4b4",
        opacity: '0.6'
    },
    IconWrapper: {
        width: "18px",
        height: "22px"
    },
    TheaterNames: {
        color: "#ffb636",
        fontFamily: "Poppins, sans-serif",
        fontSize: "14px",
        textAlign: 'center'

    },
    Divider: {
        height: 0,
        boxSizing: 'border-box' as const,
        borderBottom: "dotted 1.5px #f0f6f7",
        margin: "12px 0",
        opacity: '0.6'
    },
    TicketIcon: {
        width: "24px",
        height: "22px",
    },
    wrapperIcon: {
        display: "flex",
        gridGap: '15px',
        alignItems: 'center'
    },
    mainBoxWrapper: {
        display: "flex",
        flexDirection: 'column',
        position: "relative",
        height: '95%'
    },
    viewTypos: {
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: 600,
        color: "#b4b4b4"
    },
    cancelPolicyWrapper: {
        display: 'flex',
        backgroundColor: '#2f2617',
        flexDirection: 'column',
        gridGap: '6px',
        padding: '16px',
        borderRadius: '8px',
        width: 'fit-content',
        margin: '32px 0px'
    },
    TicketMessage: {
        backgroundColor: "#2f2617",
        color: "#fff",
        fontFamily: "Poppins, sans-serif",
        fontSize: "20px",
        fontWeight: 600
    },
    cancelPolicy: {
        fontWeight: 500,
        color: '#f3b038',
        cursor: 'pointer'
    },
    cancelText: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "18px",
        fontWeight: 300,
        color: ' #fffefe'

    },
    SeatDisplay: {
        gap: "8px",
        maxWidth: "192px",
        flexFlow: "wrap",
    },
    SeatDisplays: {
        gap: "8px",
        maxWidth: "192px",
        flexFlow: "wrap",
    },
    Dividers: {
        margin: "24px 0",
        backgroundColor: "#979797",
    },
    ScreenInfo: {
        color: "#b4b4b4",
        fontSize: "16px",
        textTransform: 'capitalize',
    },
    RupeesSymbol: {
        fontFamily: 'Roboto'
    },
    font: {
        fontSize: '18px'
    },
    PaymentWrapper: {
        padding: 0,
    },
    MenuExpandIcon: {
        color: '#ffb43a',
        cursor: "pointer",
    },
    MenuListText: {
        display: "inline-flex",
        alignItems: "center",
        "& span": {
            display: 'flex',
            gap: "8px",
            // fontWeight: 500,
            alignItems: 'center',
        },
    },
    MenuListBody: {
        marginRight: '-14px'
    },
    PaymentListItem: {
        padding: "4px 0",
        maxHeight: "36px",
        "& span": {
            fontSize: "18px",
            lineHeight: 1,
        },
        "&:last-child": {
            paddingBottom: 0,
        },
        "&:first-child": {
            paddingTop: 0,
        },
    },
    CancelBtn: {
        width: '100%',
        height: '56px',
        backgroundColor: '#ffb43a',
        borderRadius: '12px',
        color: '#202020',
        fontSize: '18px',
        textTransform: 'none',
        fontFamily: 'Poppins',
        fontWeight: 600,
        top: '2rem',
        '&:hover': {
            backgroundColor: '#ffb43a',
            color: '#202020',

        }
    },
    FindCinemaWrapper: {
        display: "flex",
        alignItems: "center",
        maxWidth: "150px",
        gap: "4px",
        "&:hover": {
            cursor: "pointer"
        }
    },
})

export default (withStyles(styles)(CancelBooking))
export { CancelBooking }
// Customizable Area End
