Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.timeout = 2000
exports.menuListMovies= "Movies";
exports.menuListITO= "ITO";
exports.menuListRequests= "Requests";
exports.menuListEvents= "Events";
exports.menuListMSports= "Sports";

exports.menuListVogue=  "Vogue";
exports.menuListHotels=  "Hotels";
exports.menuListTrains= "Trains";
exports.menuListFlights= "Flights";

exports.btnSignup= "Sign Up";
exports.labelCurrentPlay="Currently Playing";
exports.labelThisFriday="This Friday";
exports.labelComing="Coming Soon";
exports.labelSeeAll="See All";
exports.labelITO="Apply for ITO";
exports.labelEvents="Events";

exports.btnEvent= "Create Event";
exports.btnContactus= "Contact Us";
exports.labelBannerContent="Free Classifieds Using Them To Promote";
exports.labelBannerMoreContent="Your Stuff Online";
exports.btnBanner=" Apply for ITO";
exports.altRating='rating icon';

exports.labelCreateEvent="Create your Event today";
exports.labelGetInTouch="Want to get in touch with us? We would love to hear from you!";

exports.copyright="Copyright © 2023 Ticket Xchange Pvt. Ltd. All Rights Reserved.";
exports.copyrightContent="The content and images used on this website are protected by copyright and are the property of their respective owners. Using the content and pictures of this website to promote works does not constitute an endorsement of the artist. Unauthorized use is prohibited and legal responsibilities shall be investigated according to law.";

exports.guestToken = "guest_user";
exports.contentTypeApi = "application/json";
exports.apiGetMethodType = "GET";

exports.menuApiCallUrl="bx_block_categories/currently_playing/currently_playing?";
exports.citiesApiCallUrl="bx_block_location/user_cities?default_data=true";
exports.allCitiesApiCallUrl ="bx_block_location/user_cities";
exports.getAllEventEndPoint="bx_block_events/events";
exports.getMovieEventItoListing = "bx_block_categories/itos/ito_listing";
exports.EmptyMovieMessage = "No Movies are listed for this location at the moment. Explore other locations or come back soon for updates!";
exports.EmptyEventMessage = "No Events are listed for this location at the moment. Explore other locations or come back soon for updates!";
exports.EmptyMessage = "No Movies or Events are listed for this location at the moment. Explore other locations or come back soon for updates!";
exports.emptyCurrentlyPlaying = "No movies currently playing.";
exports.emptyThisFriday = "No movies releasing this Friday.";
exports.emptyComingSoon = "No upcoming movies.";
exports.labelMovies = "Movies Listing";
exports.labelEvents = "Events Listing";
// Customizable Area End