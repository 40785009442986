import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    Paper,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import EventHeader from "../../../components/src/EventHeader";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { alertImage, close } from "../../reservations2/src/assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import Loader from "../../../components/src/Loader";
import RFHDialog from "../../../components/src/RFHDialog";
import { RFHButton } from "./SelectPreference.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h1:{
            fontSize:24,
            fontWeight:600,
            '@media(max-width:600px)': {
                fontSize:16,
            },
        },
        h3:{
            fontSize:18,
            fontWeight:500,
            '@media(max-width:600px)': {
                fontSize:14,
            },
        },
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});


// Customizable Area End

import SelectTierController, {
    Props,
    configJSON
} from "./SelectTierController";

class SelectTier extends SelectTierController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    showFixedDecimalNumber = (itoPrice: string, normalPrice: string) => parseFloat(this.state.isIto ? itoPrice : normalPrice).toFixed(2)
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Loader loading={this.state.isLoading} />
                <Paper className={classes.WrapperBoxs} elevation={0} >
                <EventHeader navigation={this.props.navigation}/>
                    <Box className={classes.mainBox}>
                        <Typography className={classes.selectTier} variant="h1">Select Tier</Typography>
                        {this.state.tierList.map((tierList) => (
                            <Box className={classes.tierList} 
                                onClick={() => this.onSelectTier(tierList.id)}
                                data-test-id="selected_tier"
                                key={tierList.id}
                            >
                                <Typography variant="h3">{tierList.attributes.Tier_name}</Typography>
                                <Typography variant="h3" style={{ fontWeight: "normal" }}>
                                    <span
                                        style={{ fontFamily: "Roboto" }}>
                                        &#8377;
                                    </span>&nbsp;
                                    {this.showFixedDecimalNumber(tierList.attributes.ito_price, tierList.attributes.price)}
                                </Typography>
                            </Box>
                        ))}
                    </Box>

                    <Dialog
                        id="language"
                        open={this.state.isSeatSelection}
                        PaperProps={{
                            style: webStyle.papperStyle,
                            elevation: 0,
                        }}
                        keepMounted
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        data-test-id="seat_dialog"
                    >
                        <img
                            src={close}
                            alt='closebutton'
                            data-test-id="closeModal"
                            className={classes.crossBtn}
                            onClick={() => this.handleOnClose()}
                        />
                        <DialogContent
                            className={classes.dialogContent}
                            data-test-id="seat_number">
                            <Typography variant="h1" align="center">Select Number of Seats</Typography>
                            <Box style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                margin: "20 0"
                            }}>
                                {configJSON.seatNumber.map((elm: any) => (
                                    <Box key={elm.number} className={classes.ticketBox}>
                                        <Typography variant="h3">{elm.type}</Typography>
                                        <Box
                                            data-test-id="seat_number"
                                            onClick={() => this.onClickSeatNumber(elm.number)}
                                            className={
                                                this.state.selectedSeatNumber == elm.number
                                                    ? classes.selectedTicketNumberBtn
                                                    : classes.ticketNumberBtn}
                                        >
                                            {elm.number}
                                        </Box>
                                    </Box>
                                ))}

                            </Box>

                        </DialogContent>
                        <DialogActions >
                            <Button
                                data-test-id="continueBtn"
                                color="primary"
                                className={classes.doneBtn}
                                onClick={()=> this.handlefunctioncall()}
                            >
                                Continue
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <RFHDialog
                        data-test-id="successful-payment-dialog"
                        open={this.state.noSeat}
                        hideCloseButton={true}
                        maxWidth="xs"
                        disableEscapeKeyDown={true}
                        dialogActions={(
                            <RFHButton 
                                data-test-id="payment-done-btn" 
                                fullWidth 
                                onClick={() => { this.handleOnCloseNoSeat()}}
                                >
                                Okay
                            </RFHButton>
                        )}
                        dialogContent={
                            <Box pt={0.5} pb={0.5} display="flex" justifyContent="center" flexDirection="column">
                                <Box mb={2} display="flex" justifyContent="center">
                                    <img src={alertImage} height="48px" width="48px" />
                                </Box>
                                <Typography align="center" variant="h1">
                                    No Seats Available!
                                </Typography>
                            </Box>
                        }
                    />
                </Paper>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    papperStyle: {
        backgroundColor: "#404040",
        color: "#fff",
        padding: '48px 32px',
        maxWidth: "456px",
        width: "100%",
        margin: 5
    } as CSSProperties,
}
const styles = {
    WrapperBoxs: {
        display:"flex",
        flexDirection:"column" as const,
        alignItems:"center",
        height:"100vh",
        overflowY:"auto" as const,
    },
    mainBox:{
        maxWidth:797,
        width:"100%",
        marginTop:70,
        marginBottom:150,
    },
    selectTier:{
        '@media(max-width:600px)': {
            margin: "0px 5px",
        },
    },
    tierList:{
        width:"100%",
        margin: "32px 0 24px 0",
        padding: "32px",
        borderRadius: "6px",
        backgroundColor: "#404040",
        boxSizing:"border-box" as const,
        display:"flex",
        justifyContent:"space-between",
        cursor:"pointer",
        '@media(max-width:600px)': {
            width:"auto",
            margin: "32px 5px 24px",
            padding: "32px 10px",
        },
    },
    crossBtn:{
        width:28,
        height:28,
        position:"absolute" as const,
        top:10,
        right:10,
        cursor:"pointer"
    },
    ticketBox:{
        display:"flex",
        flexDirection:"column" as const,
        alignItems:"center",
        margin:"20px 15px",
        '@media(max-width:600px)': {
            margin:"20px 10px"
        },
    },
    selectedTicketNumberBtn:{
        border: "solid 1.1px #ffb53a",
        borderRadius: 6.5,
        marginTop:15,
        fontSize:20,
        fontWeight:600,
        width:40,
        height:40,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontFamily:"Poppins",
        cursor:"pointer",
        background:"#ffb53a",
        color:"#050505",
        '@media(max-width:600px)': {
            fontSize:18,
            width:36,
            height:36,
        },
    },
    ticketNumberBtn: {
        border: "solid 1.1px #ffb53a",
        borderRadius: 6.5,
        background: "transparent",
        marginTop:15,
        fontSize:20,
        fontWeight:600,
        fontFamily:"Poppins",
        width:40,
        height:40,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        cursor:"pointer",
        "&:hover":{
            background:"#ffb53a",
            color:"#050505"
        },
        '@media(max-width:600px)': {
            fontSize:18,
            width:36,
            height:36,
        },
       },
    doneBtn: {
        padding: '12px 80px',
        width: "100%",
        backgroundColor: '#ffb43a',
        borderRadius: 12,
        textTransform: 'capitalize' as const,
        color: '#202020',
        fontFamily: "Poppins",
        fontSize: 18,
        fontWeight: 600,
        textAlign: "center" as const,
        "&:hover": {
          backgroundColor: '#ffb43a',
        }
      },
      dialogContent:{
        '@media(max-width:600px)': {
            padding:"0px"
        },
      }
}

export default (withStyles(styles)(SelectTier))
export { SelectTier }
// Customizable Area End
