Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "reservations2";
exports.labelBodyText = "reservations2 Body";
exports.getShowScreenAPiEndPoint = "bx_block_categories/screenings/";
exports.getAPiMethod = "GET";
exports.postAPiMethod = "POST";
exports.deleteAPiMethod = 'DELETE';
exports.putAPIMethod = 'PUT';

exports.btnExampleTitle = "CLICK ME";

exports.labelCast = "Cast";
exports.labelCrew = "Crew";
exports.labelReview = "Review";
exports.btnBookTickets = 'Book tickets';
exports.btnRateNow = 'Rate Now';
exports.altCastCrew = 'castcrew img';
exports.releaseDate = 'Release Date : ';
exports.bookedOn = 'Booked on';
exports.labelMovieDesc = "Movie Description";
exports.votes = "(10.1 K) Votes";
exports.labelAddRating = "Add Rating & Review";
exports.labelFeelFree = "Feel free to rate and review";
exports.labelSeeAll = "seeAll"
exports.guestToken = "guest_user";
exports.contentTypeApi = "application/json";
exports.apiGetMethodType = "GET";

exports.reviewApiCallUrl = "bx_block_reviews/reviews";
exports.timeSlotsApiEndpoint = "bx_block_categories/screenings";
exports.menuAPIEndPoint = "bx_block_catalogue/menus";
exports.addItemApiEndPoint = "bx_block_catalogue/menus/{MENU_ITEM_ID}/add_cart"
exports.removeItemApiEndPoint = 'bx_block_catalogue/menus/{MENU_ITEM_ID}/destroy_cart'
exports.createBookingEndPoint = 'bx_block_categories/bookings/regular_booking'
exports.bookableMovieType = "BxBlockCategories::Movie"
exports.getBookingDataApiEndPoint = 'bx_block_categories/bookings/{BOOKING_ID}'
exports.updateBookingApiEndPoint = 'bx_block_categories/bookings/{BOOKING_ID}'
exports.sendMTicketApiEndPoint = "bx_block_categories/bookings/{BOOKING_ID}/share_m_ticket"
exports.sendMTicketEventApiEndPoint = "bx_block_events/event_bookings/{BOOKING_ID}/share_m_ticket"
exports.getLanguagesFilterApiEndPoint = "bx_block_categories/movies/movie_languages";
exports.getFormatFilterApiEndPoint = "bx_block_categories/movies/movie_screen_types";
exports.getGenreFilterApiEndPoint = "bx_block_categories/movies/movie_category_types";

exports.createOrderEndPoint = "bx_block_scheduling/payments"
exports.getOrderEndPoint = "bx_block_scheduling/payments/get_order"

exports.paymentEndPoint = "bx_block_categories/bookings"
exports.BookingApiEndPoint = "bx_block_categories/bookings/:id"
exports.EventBookingApiEndPoint = "bx_block_events/event_bookings/:id"
exports.apiPutMethodType = "PUT";

exports.termsAndConditionsEndPoint = "bx_block_termsandconditions/movie_terms_and_conditions?type=RegularBookingTermsAndCondition"
exports.labelSeeAll = "See All";
exports.likeApiEndPoint = "bx_block_like/likes"
exports.removeLikeApiEndPoint = "/bx_block_like/likes/destroy_like"
exports.dislikeikeApiEndPoint = "bx_block_like/likes/dislike"
exports.removeDislikeApiEndPoint = "/bx_block_like/likes/destroy_dislike"
exports.getAverageApiEndPoint = "bx_block_reviews/reviews/average_ratings"
exports.MovieTicketMessage = 'This ticket can be cancelled up to 30 mins before the show.'
exports.MTicketLabel = "M-Ticket"
exports.Duration = "Duration: ";
exports.ViewDetails = "View Details"

exports.Proceed = "Proceed";
exports.SoldOut = "Sold Out";
exports.SoldOutDescription = "You can place a request for sold out tickets or buy tickets which are listed to Sell.";

exports.Selected = "Selected";
exports.Available = "Available";
exports.Request = "Request";
exports.Unavailable = "Unavailable";
exports.Buy = "Buy";

exports.PriceAlertContent = "Request price should be minimum";
exports.EnterPriceTitle = "Enter your request Price per Ticket";
exports.TicketHolder = "Ticket Holder:";
exports.Tier = "Tier:";
exports.WalletBalance = "Wallet Balance :";
exports.Back = "Back";
exports.Continue = "Continue";
exports.ConfirmationContentTitle = "Your Request Price";
exports.Edit = "Edit";
exports.Done = "Done";
exports.ReqeustPlaceTitle = "Your Request is Placed!";
exports.RequestPlaceContent = "You’ll be notified once there are tickets put up for resale";
exports.InsufficientBalanceTitle = "Insufficient Wallet Balance";
exports.InsufficientBalanceContent = "Add Money to proceed";
exports.AddMoneyToWalletBtn = "Add Money to Wallet";

exports.APIContentType = "application/json";
exports.GetAPIMethod = "GET";
exports.PutAPIMethod = "PUT";
exports.PostAPIMethod = "POST";
exports.getAccountDetailsEndPoint = "account_block/accounts";
exports.GetWalletBalanceApiEndPoint = "bx_block_scheduling/wallet_transactions/wallet_ballance";
exports.CreateRequestBookingApiEndPoint = "bx_block_categories/booking_requests";
exports.ResellRequestBookingApiEndPoint = "bx_block_categories/booking_requests/resell_booking";
exports.notifyMeApiEndPoint = "bx_block_categories/booking_requests/new_booking_sell_notify";

exports.MinimumPriceErrorMessage = "Request price should be minimum"
exports.updateEventPaymentEndPoint="bx_block_events/event_bookings";
exports.itoCloseIn = "ITO Closes in";
exports.itoPricePerTicket = "ITO Price per Ticket";
exports.SelectNumberOfSeats = "Select Number of Seats";
exports.BookTickets = "Book Tickets";
exports.Tickets = "Tickets";
exports.NoReviewsMessage = "No Reviews Yet!";
exports.proceedToPay = "Proceed to Pay";
exports.subTotal = "Sub - Total";
exports.convenienceFees = "Convenience Fees";
exports.baseAmount = "Base Amount";
exports.discount = "Discount";
exports.foodBeverage = "Food & Beverage";
exports.remove = "Remove";
exports.totalAmount = "Total Amount";
exports.contactInformation = "Contact Information"
exports.foodAndBeverages = "Food & Beverages"
exports.skip = "Skip";
exports.UA = "U/A";
exports.yourRating = "Your Rating";
exports.selectLanguageAndFormat = "Select Language & Format";
exports.languageLabel = "Select Language";
exports.cancel = "Cancel";
exports.accept = "Accept";
exports.termsAndCondition = "Terms & Conditions";
exports.noDataAvailable = "No Data Available";
exports.itemSelected = "item selected";
exports.itemsSelected = "items selected";
exports.notifyMe = "Notify me";

exports.labelCurrentlyPlaying = "Currently Playing";
exports.labelThisFriday = "This Friday";
exports.labelComingSoon = "Coming Soon";
exports.menuApiCallUrl="bx_block_categories/currently_playing/currently_playing";
// Customizable Area End