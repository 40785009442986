import React from "react";

// Customizable Area Start
import { Box, CircularProgress, Container, CssBaseline, Fade, IconButton, ThemeProvider, Typography, createTheme, makeStyles, styled } from "@material-ui/core";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { getFormattedDate, goToPrevPage } from "../../../components/src/utilities";
import { ArrowBackIosOutlined, ChevronLeft, ChevronRight } from "@material-ui/icons";
import { ITransaction } from "../../../components/src/interfaces.web";
import { FailedTransactionIcon, LightWalletIcon, ListBarcodeIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    background: {
      default: "#121212",
      paper: "#40404066"
    }
  },
  typography: {
    fontFamily: "Poppins",
    h3: {
      fontSize: "24px",
      fontWeight: 600,
      color: "#FFF",
      "@media (max-width: 600px)": {
        fontSize: "18px",
      }
    },
    h4: {
      fontSize: "24px",
      fontWeight: 600,
      color: "#FFF",
      marginBottom: "16px",
      "@media (max-width: 600px)": {
        fontSize: "16px",
      }
    },
    body1: {
      fontSize: "18px",
      color: "#FFF",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      }
    },
    body2: {
      fontSize: "16px",
      color: "#b4b4b4",
      fontWeight: 600,
      "@media (max-width: 600px)": {
        fontSize: "10px",
      }
    },
    subtitle1: {
      fontSize: "18px",
      color: "#b4b4b4",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      }
    },
    subtitle2: {
      fontSize: "16px",
      color: "#b4b4b4",
      "@media (max-width: 600px)": {
        fontSize: "10px",
      }
    }
  }
})

const RFHIconButton = styled(IconButton)({
  color: "#fff",
  cursor: "pointer",
  "&:disabled": {
    color: "#fff",
    cursor: "not-allowed",
    "&&:hover": {
      cursor: "not-allowed",
    },
  },
})
// Customizable Area End

import TransactionHistoryController, { Props, configJSON } from "./TransactionHistoryController";

export default class TransactionHistory extends TransactionHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <MainHeaderComp navigation = {this.props.navigation} />

        <Box flex={1}>
          <Container>

            <Box display="flex" alignItems="center" mt={4} mb={4}>
              <IconButton style={styles.BackButton} data-test-id="button" onClick={goToPrevPage}>
                <ArrowBackIosOutlined style={styles.BackButtonIcon} />
              </IconButton>

              <Typography variant="h3">{configJSON.TransactionHistoryLabel}</Typography>
            </Box>

            <Typography variant="h4">{configJSON.RecentTransactions}</Typography>

            {
              this.state.isLoading ? (
                <Fade in={this.state.isLoading} unmountOnExit>
                  <Box display="flex" justifyContent="center">
                    <CircularProgress style={styles.CircularProgress} />
                  </Box>
                </Fade>
              ) : (
                <Box p={{ xs: 0, sm: 1 }} maxWidth="968px">

                  {
                    this.state.transactionList.map((transaction: ITransaction) => <TransactionRecord key={transaction.id} transaction={transaction} />)

                  }
                  {/* Pagination */}
                  {
                    this.state.transactionList.length ? (
                      <Box display="flex" alignItems="center" flexDirection="row" justifyContent="flex-end" mt={2}>

                        <RFHIconButton
                          data-test-id="pagination-prev-button"
                          disabled={typeof this.state.meta.prev_page !== "number"}
                          onClick={() => this.getTransactions(this.state.meta.prev_page)}
                        >
                          <ChevronLeft />
                        </RFHIconButton>
                        {
                          this.state.meta.prev_page && (
                            <Typography style={styles.PageNumber}>{this.state.meta.prev_page}</Typography>
                          )
                        }
                        {
                          !!this.state.meta.current_page && (
                            <Typography data-test-id="pagination-current-page" style={styles.ActivePageNumber} align="center">{this.state.meta.current_page}</Typography>
                          )
                        }
                        {
                          this.state.meta.next_page && (
                            <Typography style={styles.PageNumber}>{this.state.meta.next_page}</Typography>
                          )
                        }
                        <RFHIconButton
                          data-test-id="pagination-next-button"
                          disabled={typeof this.state.meta.next_page !== "number"}
                          onClick={() => this.getTransactions(this.state.meta.next_page)}
                        >
                          <ChevronRight />
                        </RFHIconButton>

                      </Box>
                    ) : (
                      <Typography data-test-id="error-message" align="center" style={styles.TextTransform}>{this.state.errorMessage || configJSON.noTransactionsFound}</Typography>
                    )
                  }
                </Box>
              )
            }




          </Container>
        </Box>

        <FooterWithEvents />

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  TextTransform: {
    textTransform: "capitalize" as const
  },
  BackButton: {
    width: "32px",
    height: "32px",
    backgroundColor: "#ffb43a",
    marginRight: "16px"
  },
  BackButtonIcon: {
    width: "24px",
    height: "24px"
  },
  CircularProgress: {
    color: "#ffb43a"
  },
  PageNumber: {
    color: "#fff",
    fontSize: "18px",
    padding: "12px",
    fontFamily: "Poppins, sans-serif",
  },
  ActivePageNumber: {
    fontSize: "18px",
    margin: "12px",
    height: "26px",
    width: "26px",
    backgroundColor: "#ffb43a",
    fontFamily: "Poppins, sans-serif",
    borderRadius: "4px",
    border: "none",
    color: "#000"
  },
}

const useStyles = makeStyles({
  Wrapper: {
    display: "flex",
    alignItems: "center",
    padding: "40px 0 32px",
    borderBottom: "solid 1px #404040",
    "@media (max-width: 600px)": {
      alignItems: "flex-start",
      padding: "20px 0 14px",

    }
  },
  IconWrapper: {
    width: "72px",
    height: "72px",
    marginRight: "20px",
    "@media (max-width: 600px)": {
      width: "32px",
      height: "32px",
      marginRight: "14px"
    }
  },
  ContentFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "10px"
  },
  Time: {
    fontWeight: 400,
  },
  RupeesIcon: {
    fontFamily: "Roboto, sans-serif"
  },
  Amount: {
    fontWeight: 400
  },
  Status: {
    textTransform: "capitalize",
    color: "#ff3b30"
  },
  FailedIcon: {
    marginRight: "6px"
  },
  CreditAmount: {
    color: "#ffb43a"
  },
  DebitAmount: {
    color: "#FFF"
  },
  Description: {
    textTransform: "capitalize"
  }
})

interface ITransactionRecordProps {
  transaction: ITransaction
}

enum TransactionType {
  Credit = "credit",
  Debit = "debit",
  Refunded = "refunded"
}

enum TransactionStatus {
  Failed = "failed",
  Success = "success"
}

export const TransactionRecord: React.FC<ITransactionRecordProps> = ({ transaction }) => {

  const classes = useStyles()

  return (
    <Box className={classes.Wrapper}>

      <Box className={classes.IconWrapper}>
        <img width="100%" height="100%" src={transaction.transaction_type === TransactionType.Credit ? LightWalletIcon : ListBarcodeIcon} alt="wallet-icon" />
      </Box>

      <Box flex={1} className={classes.ContentFlex}>
        <Typography variant="body1" className={classes.Description}>{transaction.description}</Typography>
        <Typography variant="body2">
          {getFormattedDate(transaction.created_at, "DD MMM YYYY")}
          <span className={classes.Time}>&nbsp;&nbsp;{getFormattedDate(transaction.created_at, "hh:mm A")}</span>
        </Typography>
      </Box>

      <Box textAlign="end" className={classes.ContentFlex}>
        {
          transaction.status === TransactionStatus.Failed ? (
            <Typography variant="subtitle1">
              <span className={classes.RupeesIcon}>&nbsp;&#8377;&nbsp;</span>
              {transaction.amount.toFixed(2)}
            </Typography>
          ) : (
            <>
              {
                transaction.transaction_type && [TransactionType.Credit, TransactionType.Refunded].includes(transaction.transaction_type as TransactionType) ? (
                  <Typography variant="subtitle1" className={classes.CreditAmount}>
                    +
                    <span className={classes.RupeesIcon}>&nbsp;&#8377;&nbsp;</span>
                    {transaction.amount.toFixed(2)}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" className={classes.DebitAmount}>
                    -
                    <span className={classes.RupeesIcon}>&nbsp;&#8377;&nbsp;</span>
                    {transaction.amount.toFixed(2)}
                  </Typography>
                )
              }
            </>
          )
        }
        <Typography variant="subtitle2">
          {
            transaction.status === TransactionStatus.Failed ? (
              <Box display="flex" alignItems="center" justifyContent="end">
                <img src={FailedTransactionIcon} alt="transaction-fail" className={classes.FailedIcon} />
                <span className={classes.Status}>{transaction.status}</span>
              </Box>
            ) : (
              <>
                {
                  !!transaction.total_amount && (
                    <Typography variant="subtitle2">
                      {configJSON.ClosingBalance}
                      <span className={classes.RupeesIcon}>&nbsp;&#8377;&nbsp;</span>
                      {transaction.total_amount?.toFixed(2)}
                    </Typography>
                  )
                }
              </>
            )
          }
        </Typography>
      </Box>

    </Box>
  )
}
// Customizable Area End
