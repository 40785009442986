import React from "react";

// Customizable Area Start

import {
    Box,
    CircularProgress,
    ClickAwayListener,
    Container,
    Fade,
    IconButton,
    MenuItem,
    Popover,
    Popper,
    Typography,
} from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import { ArrowBackIosOutlined, ChevronLeft, ChevronRight, TuneOutlined } from "@material-ui/icons";
import { EventMovieMobileTicket, MRFHTab, MRFHTabs, RFHIconButton, RFHMenuList, RFHTab, RFHTabRoot, styles } from "./BookingHistoryView.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { ITOFilterOption } from "../../../components/src/enums.web";
import { FilterIcon } from "./assets";
import { goToPrevPage } from "../../../components/src/utilities";
import { eventOrMovieBookingData, IItoBookingResponse, ISelectOption } from "../../../components/src/interfaces.web";
import ITOBookingHistoryListing from "../../../components/src/ITOBookingHistoryListing.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h6: {
          fontSize: "18px",
          color: "#b4b4b4",
          fontWeight: "normal"
        }
      }
});


const ITOFilterOptionList: ISelectOption<ITOFilterOption>[] = [
    { label: "All", value: ITOFilterOption.All },
    { label: "Application Pending", value: ITOFilterOption.Pending },
    { label: "Application Successful", value: ITOFilterOption.Successfull },
    { label: "Application Unsuccessful", value: ITOFilterOption.Unsuccessfull },
    { label: "Listed to Sell", value: ITOFilterOption.ListToSell },
    { label: "Invalid", value: ITOFilterOption.Invalid }
    
]
// Customizable Area End

import ITOBookingHistoryController, {
    Props,
    configJSON
} from "./ITOBookingHistoryController";

class ITOBookingHistory extends ITOBookingHistoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box style={styles.MainWrapper}>

                    <Box style={styles.MinHeight}>

                        <Box display={{ xs: "none", sm: "block" }}>
                            <MainHeaderComp navigation={this.props.navigation} />

                            <Container data-test-id="test-id">
                                <Box display="flex" mt={3} mb={3} justifyContent="space-between">
                                    <Box display="flex" alignItems="center">
                                        <IconButton 
                                            data-test-id="back-button"
                                            style={styles.BackButton} 
                                            onClick={() => goToPrevPage()}
                                        >
                                            <ArrowBackIosOutlined />
                                        </IconButton>

                                        <span style={styles.Title}>{configJSON.ITOHistoryHeading}</span>
                                    </Box>

                                    <Box display="flex" mr={3} alignItems="center">
                                        <IconButton
                                            style={styles.FilterIcon}
                                            data-test-id="filter-menu-button"
                                              onClick={this.handleFilterClick}
                                            aria-controls={this.state.filterAnchorEl ? 'basic-menu' : undefined}
                                            aria-expanded={this.state.filterAnchorEl ? 'true' : undefined}
                                            aria-haspopup="true"
                                        >
                                            <img 
                                                alt="Filter-Button"
                                                height="22px"  
                                                width="22px" 
                                                src={FilterIcon} 
                                            />
                                            <span style={styles.FilterText}>{configJSON.Filter}</span>
                                        </IconButton>

                                        <Popper
                                            anchorEl={this.state.filterAnchorEl}
                                            open={!!this.state.filterAnchorEl}
                                            role={undefined}
                                            style={styles.zIndex}
                                            placement="bottom-end"
                                            disablePortal
                                            transition
                                        >
                                            <Popover
                                                anchorEl={this.state.filterAnchorEl}
                                                open={!!this.state.filterAnchorEl}
                                                onClose={this.handleFilterClose}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                PaperProps={{
                                                    style: {
                                                        background: 'transparent !important',
                                                        borderRadius: "12px",
                                                        boxShadow: "none"
                                                    },
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <ClickAwayListener
                                                    onClickAway={this.handleFilterClose}
                                                >
                                                    <RFHMenuList
                                                        autoFocusItem={!this.state.filterAnchorEl}
                                                        data-test-id="filter-menu-root"
                                                        id="composition-menu"
                                                    >

                                                        {
                                                            ITOFilterOptionList.map(option => (
                                                                <MenuItem
                                                                    data-test-id="filter-menu"
                                                                    key={option.value}
                                                                    selected={this.state.selectedFilter === option.value}
                                                                    onClick={() => this.handleChangeFilter(option.value)}
                                                                >{option.label}</MenuItem>
                                                            ))
                                                        }

                                                    </RFHMenuList>
                                                </ClickAwayListener>
                                            </Popover>

                                        </Popper>

                                    </Box>
                                </Box>

                                {/* -------- WEB VIEW START --------- */}
                                <Container style={styles.ListingContainer}>

                                    <Box gridGap="25px" display="flex" flexDirection="column">

                                        {/* LISTING OF BOOKING */}
                                        {
                                            this.state.isLoading ? (
                                                <Fade unmountOnExit in={this.state.isLoading}>
                                                    <Box display="flex" justifyContent="center">
                                                        <CircularProgress style={styles.CircularProgress} />
                                                    </Box>
                                                </Fade>
                                            )
                                                : (
                                                    <Box display="flex" flexDirection="column" gridGap="25px" data-test-id="ticket-listing-wrapper">

                                                        {
                                                            this.state.itoBookingHistory.length > 0 ? (
                                                                this.state.itoBookingHistory.map((booking: eventOrMovieBookingData) => {
                                                                    return (
                                                                        <ITOBookingHistoryListing
                                                                            data-test-id="ito_booking_listing"
                                                                            key={booking.id}
                                                                            booking={booking as IItoBookingResponse}
                                                                            message={configJSON.BuyOrSellMsg}
                                                                            redirectToViewTicket={this.redirectToViewTicketpage}
                                                                        />)
                                                                })
                                                            ) : (
                                                                <Typography style={styles.NoDataMessage}>{configJSON.NoDataFound}</Typography>
                                                            )
                                                        }
                                                    </Box>
                                                )
                                        }

                                        {/* ----- Pagination ------ */}
                                        {
                                            this.state.itoBookingHistory.length > 0 ? (
                                                <Box display="flex" alignItems="center" flexDirection="row" justifyContent="flex-end">

                                                    <RFHIconButton
                                                        data-test-id="pagination-prev-button"
                                                        disabled={typeof this.state.itoPagination.prev_page !== "number"}
                                                        onClick={() => this.getITOBookingHistory(this.state.selectedFilter, this.state.itoPagination.prev_page)}
                                                    >
                                                        <ChevronLeft />
                                                    </RFHIconButton>
                                                    {
                                                        this.state.itoPagination.prev_page && (
                                                            <Typography style={styles.PageNumber}>{this.state.itoPagination.prev_page}</Typography>
                                                        )
                                                    }
                                                    {
                                                        !!this.state.itoPagination.current_page && (
                                                            <Typography data-test-id="pagination-current-page" style={styles.ActivePageNumber} align="center">{this.state.itoPagination.current_page}</Typography>
                                                        )
                                                    }
                                                    {
                                                        this.state.itoPagination.next_page && (
                                                            <Typography style={styles.PageNumber}>{this.state.itoPagination.next_page}</Typography>
                                                        )
                                                    }
                                                    <RFHIconButton
                                                        data-test-id="pagination-next-button"
                                                        disabled={typeof this.state.itoPagination.next_page !== "number"}
                                                        onClick={() => this.getITOBookingHistory(this.state.selectedFilter, this.state.itoPagination.next_page)}
                                                    >
                                                        <ChevronRight />
                                                    </RFHIconButton>

                                                </Box>
                                            ) : null
                                        }

                                    </Box>

                                </Container>
                                {/* ------- WEB VIEW END ---------*/}

                            </Container>
                            <FooterWithEvents />
                        </Box>

                        {/* ------ MOBILE VIEW START ------ */}

                        <Box style={styles.MobileWrapper} display={{ xs: "block", sm: "none" }}>

                            <Box style={styles.MobileHeader}>

                                <Container>
                                    <Box  
                                        mt={1.5} mb={1.5}
                                        justifyContent="space-between" 
                                        display={{ xs: "flex", sm: "none" }}
                                    >

                                        <Box display="flex" alignItems="center">
                                            <IconButton style={styles.MBackButton} onClick={goToPrevPage}>
                                                <ArrowBackIosOutlined style={{ width: "16px", height: "16px" }} />
                                            </IconButton>

                                            <span style={styles.MTitle}>{configJSON.ITOHistoryHeading}</span>
                                        </Box>

                                        <Box display="flex" alignItems="center">
                                            <IconButton
                                                onClick={this.handleFilterClick}
                                                style={styles.FilterIcon}
                                                aria-haspopup="true"
                                                aria-controls={this.state.filterAnchorEl ? 'basic-menu' : undefined}
                                                aria-expanded={this.state.filterAnchorEl ? 'true' : undefined}
                                            >
                                                <TuneOutlined />
                                            </IconButton>
                                        </Box>

                                    </Box>
                                </Container>

                            </Box>

                            <Box style={styles.ListingWrapper} >

                                {/* ------ LISTING OF BOOKING ------ */}
                                {
                                    this.state.isLoading ? (
                                        <Fade unmountOnExit in={this.state.isLoading}>
                                            <Box display="flex" justifyContent="center">
                                                <CircularProgress style={styles.CircularProgress} />
                                            </Box>
                                        </Fade>
                                    ) :
                                        (
                                            <>
                                                {
                                                    this.state.itoBookingHistory.length > 0 ? (
                                                        this.state.itoBookingHistory.map((booking: eventOrMovieBookingData) => {
                                                            return (
                                                                <ITOBookingHistoryListing
                                                                    key={booking.id}
                                                                    mobileCard={true}
                                                                    booking={booking as IItoBookingResponse}
                                                                    message={configJSON.BuyOrSellMsg}
                                                                    redirectToViewTicket={this.redirectToViewTicketpage}
                                                                />)
                                                        })
                                                    ) : (
                                                        <Typography style={styles.NoDataMessage}>{configJSON.NoDataFound}</Typography>
                                                    )
                                                }
                                            </>
                                        )
                                }

                                {
                                    this.state.itoBookingHistory.length > 0 ? (
                                        <Box flexDirection="row" display="flex" alignItems="center" justifyContent="flex-end">

                                            <RFHIconButton
                                                onClick={() => this.getITOBookingHistory(this.state.selectedFilter, this.state.itoPagination.prev_page)}
                                                disabled={typeof this.state.itoPagination.prev_page !== "number"}>
                                                <ChevronLeft />
                                            </RFHIconButton>
                                            
                                            {
                                                this.state.itoPagination.prev_page && (
                                                    <Typography style={styles.PageNumber}>{this.state.itoPagination.prev_page}</Typography>
                                                )
                                            }

                                            {
                                                !!this.state.itoPagination.current_page && (
                                                    <Typography style={styles.ActivePageNumber} align="center">{this.state.itoPagination.current_page}</Typography>
                                                )
                                            }

                                            {
                                                this.state.itoPagination.next_page && (
                                                    <Typography style={styles.PageNumber}>{this.state.itoPagination.next_page}</Typography>
                                                )
                                            }
                                            
                                            <RFHIconButton
                                                onClick={() => this.getITOBookingHistory(this.state.selectedFilter, this.state.itoPagination.next_page)}
                                                disabled={typeof this.state.itoPagination.next_page !== "number"}>
                                                <ChevronRight />
                                            </RFHIconButton>

                                        </Box>
                                    ) : null
                                }
                            </Box>
                        </Box>

                        {/* ------- MOBILE VIEW END ------- */}
                    </Box>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyles = {
    WrapperBoxs: {
        minHeight: '100vh',
    },
}

export default (withStyles(webStyles)(ITOBookingHistory))
export { ITOBookingHistory }
// Customizable Area End
