Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.facebookButtonText = "Sign up With Facebook";
exports.PasswordRequiredMessage = "Password Required";
exports.googleButtonText = "Sign up With Google";
exports.signUpButtonText = "Sign up with email and password";
exports.orText = "or";
exports.signUpBodyText = "Value proposition: why users should sign up.";
exports.signUpTitleText = "Sign up";
exports.signUpCallMethod = "POST";
exports.signUpPhoneEndPoint='account_block/accounts'
exports.signUpGoogleEndPoint='account/accounts'
exports.signUpEmailEndPoint='account_block/accounts'
exports.otpConfirmEndPoint='account_block/accounts/sms_confirmations'
exports.resendCodeEndPoint='account_block/accounts/send_otps'
exports.errorsMessage = "Password should contain minimum length 8, one digit, one uppercase letter, one lowercase letter, one special character, & no spaces"
exports.TermsAndConditionsURL = "bx_block_termsandconditions/terms_and_conditions"
exports.PrivacyPolicyURL = 'bx_block_privacy_policy/policy';
exports.emailExitMes = "Account Already Exist";
exports.phoneNumberError = "Enter a valid 10 digit Mobile Number";
exports.ReferralText = "Been referred by a friend?";
exports.ReferralLinkText = "Add referral code";
exports.AddReferralCode = "Add Referral Code";
exports.ReferralCode = "Referral Code";
exports.Apply = "Apply";
exports.AppliedReferralText = "Referral Code applied.";
exports.AppliedReferralRemove = "Remove";
exports.contentTypeApi = "application/json";
exports.apiGetMethodType = "GET";
exports.verifyReferralCodeEndpoint = "bx_block_share/referral_codes/verify_referral?code={CODE}";
exports.getAccountDetailsEndPoint = "account_block/accounts";
// Customizable Area End