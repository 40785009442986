import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Fade,
    Grid,
    Popover,
    Typography,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import {  check, checkboxicon, resetImg } from "../../reservations2/src/assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import ReactPaginate from 'react-paginate';
import Promotecontent from "../../promotecontent/src/Promotecontent.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { IEventFilter} from "../../../components/src/interfaces.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const BookTicketBtn = styled(Button)({
    backgroundColor: "#121212",
    padding: "12px",
    textAlign: "center",
    maxHeight: "40px",
    color: "#fff",
    border: "0.5px solid #FFB636",
    borderRadius: "4px",
    fontFamily: "Poppins",
    textTransform: "capitalize",
    position: "relative",
    zIndex: 10,
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#FFB636",
        color: "#000"
    }
})

const CheckboxContainer = styled(Checkbox)(({ theme }) =>({
    root: {
        color: "#ffb43a",
        '&$checked': {
            color: '#ffb43a',
        },
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',
            left: 4,
            height: 15,
            width: 15,
            position: "absolute",
            top: 4,
            zIndex: -1
        },
        padding: '0px',
        marginRight: "8px"
    },
    checked: {}
}));

const Dropdownbtn = styled(Button)(({ theme }) => ({
    height: "48px",
    padding: "13px 16px 12px",
    border: "solid 0.5px #ffb636",
    borderRadius: "8px",
    backgroundColor: "#2f2617",
    color: "#ffffff",
    margin: '5px 18px 5px 0px',
    '&:hover': {
        backgroundColor: "#2f2617"
    },
    textTransform: "capitalize",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    '@media(max-width:700px)': {
        padding: "13px 10px 12px",
        margin: '5px 10px 5px 0px',
    }
}));

const ReactPaginateComp = styled(ReactPaginate)(({ theme }) => ({
    display:"flex",
    color:"#fff",
    fontSize:18,
        fontFamily:"Poppins",
    '& > li':{
      listStyle:"none",
      width: 25,
      height: 25,
      marginLeft: 10,
      textAlign: "center",
      cursor:"pointer"
    },
    '& .selected':{
      background:"#ffb636",
      borderRadius:4,
      color:"#000"
    }
  }))
  
type HandleClickFilterType = (event: React.MouseEvent<HTMLButtonElement>) => void;

type HandleCloseFilterType = () => void;

type HandleFilterType = (langId:string) => void;


// Customizable Area End

import AllEventController, {
    Props,
} from "./AllEventController";

class AllEvent extends AllEventController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    PlanPremiumIcon = () => {
        return <img src={checkboxicon} alt="images" width="20px" />;
    }

    PlanPremiumIcon2 = () => {
        return <img src={check} alt="images" width="20px" />;
    }

    FilterButton = (
        DataList: IEventFilter[], 
        title: string, 
        handleClick: HandleClickFilterType, 
        handleClose: HandleCloseFilterType, 
        anchorEl: HTMLElement | null, 
        selectedIds: string[], 
        handleFilter: HandleFilterType
    ) => {

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <>
                <Dropdownbtn onClick={handleClick}
                    data-test-id = {`${title}-filter-button`}>
                    {title}
                    {selectedIds.length > 0 ?
                        <span>
                            {`(${selectedIds.length})`}
                        </span>
                        : ''}

                    {open ? (
                        <ExpandLessIcon style={webStyle.downArrow} />
                    ) : (
                        <KeyboardArrowDownIcon style={webStyle.downArrow} />
                    )}
                </Dropdownbtn>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            borderRadius: '12px',
                            marginTop: "12px",
                            width: "190px",
                            maxHeight: "232px"
                        },
                    }}
                    data-test-id="anchorbtn"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >{DataList.map((list, index: number) => {
                    let checkedData = selectedIds.find((langId : string) => langId == list.value) ? true : false ;
                    return (
                        <div
                            style={index % 2 == 0 ? webStyle.lightModeListStyle : webStyle.darkModeListStyle}
                            onClick={() => handleFilter(list.value)}
                            data-test-id = {`${title}-fiter-events`}
                        >
                            <CheckboxContainer
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                data-test-id="checkboxt"
                                checked={checkedData || false}
                                checkedIcon={this.PlanPremiumIcon()}
                                icon={this.PlanPremiumIcon2()}
                            />
                            <Typography style={webStyle.listText}>{list.label}</Typography>
                        </div>
                    )
                })}</Popover>
            </>
        )
    }
    
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <div style={webStyle.bgcolor}>
                    <MainHeaderComp navigation = {this.props.navigation} />
                    <Container maxWidth="lg">
                    {this.state.loading ? <Fade in={this.state.loading} unmountOnExit>
                        <Box display="flex" justifyContent="center">
                            <CircularProgress style={webStyle.CircularProgress} />
                        </Box>
                    </Fade> : <>
                        <Grid container style={webStyle.main} spacing={3}>
                            <Grid container justifyContent="space-between" item xs={12} alignItems="center">
                                <Grid item>
                                    {this.FilterButton(
                                        this.state.languages, 
                                        "Languages",
                                        this.handleClickLanguageFilter, 
                                        this.handleCloseLanguageFilter,
                                        this.state.isLanguage,
                                        this.state.selectedLanguageIds,
                                        this.handleFilterLanguages,
                                    )}
                                    {this.FilterButton(
                                        this.state.genersList, 
                                        "Genres",
                                        this.handleClickGenersFilter, 
                                        this.handleCloseGenersFilter,
                                        this.state.isGenres,
                                        this.state.selectedGenreIds,
                                        this.handleFilterGenres,
                                    )}
                                </Grid>
                                <Grid item>
                                    <div data-test-id="resetFilterClick" style={webStyle.resetstyle} onClick={this.reseFiletrs}>
                                        <img 
                                            alt="resetImg"
                                            src={resetImg}
                                            style={webStyle.resetImgStyle}
                                         />
                                        Reset
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            {
                                ( this.state.allEventList).map((event) => {
                                    return (
                                        <EventCard 
                                            key={event.id}
                                            objEvent={event} 
                                            id={""} 
                                            navigation={this.props.navigation} 
                                            classes={classes}
                                        />
                                    )
                                })
                            }
                        </Grid>

                        <Box data-test-id="pagination-box" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <ReactPaginateComp
                    data-test-id="pagination"
                    nextLabel=">"
                    onPageChange={(e) => this.handleOnPageChange(e)}
                    pageCount={this.state.pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                </Box>
                        </>}
                    </Container>
                <FooterWithEvents />                
                </div >
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = {
    WrapperBoxs: {
        minHeight: '100vh',
    },
}

const webStyle = {
    CircularProgress: {
        marginTop: "20px",
        color: "#ffb43a"
    },
    BoxWrapper: {
        padding: "10px"
    },
    bgcolor: { backgroundColor: "#121212"},
    resetstyle: {
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontFamily: 'Poppins',
        cursor: 'pointer'
    },
    resetImgStyle: {
        height: "20px",
        width: '20px',
        marginRight: '8px'
    },
    categorytxt: {
        color: "#fff",
        margin: "10px",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: "bold"
    },
    eventNameTxt: {
        color: "#fff",
        margin: "10px",
        fontWeight: "bold",
        fontFamily: "Poppins",
        fontSize: "16px"
    },
    posterImgStyle:{
        objectFit: 'fill',
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0px',
        left: '0px',
    }as CSSProperties,
    main: {
        display: "flex",
        justifyContent: "space-between",
        padding: '48px 0px 30px 0px'
    },
    languagetxt: {
        color: "#fff",
        margin: "10px",
        fontSize: "12px",
        fontFamily: "Poppins",
    },
    fadeStyle: {
        bottom: "10px",
        width: "100%",
        transition: "0.8s ease-in-out",
        position: "absolute",
        background:"linear-gradient(to bottom, rgba(18, 18, 18, 0), rgba(18, 18, 18, 0.6), #362203)"
    },
    div1Style: {
        height: "100%",
        width: "100%",
        border: "2px solid #FFB636",
        position: "relative",
    },
    eventCardDiv:{
        height: '100%',
        width: '100%',
        position: 'relative',
        zIndex: 1,
    }as CSSProperties,
    downArrow: {
        color: '#ffb43a'
    },
    lightModeListStyle: {
        padding: '10px',
        display: "flex",
        minWidth: "100px",
        backgroundColor: "#5a5a5a",
    },
    darkModeListStyle: {
        display: "flex",
        padding: '10px',
        minWidth: "100px",
        backgroundColor: "#404040",
    },
    listText: {
        display: 'flex',
        color: '#ffffff',
        alignItems: 'center',
        fontFamily: "Poppins"
    },
};

export class EventCard extends AllEvent {
    constructor(props: any) {
        super(props);
    }

    handleMouseEnter = () => {
        this.setState({ isHover: true });
    };

    handleMouseLeave = () => {
        this.setState({ isHover: false });
    };

    render() {
        const { isHover } = this.state;
        const { objEvent:
            { id,
                attributes: {
                    languages,
                    poster_url,
                    name,
                    show_type,
                    is_promoted
                } } }: any = this.props;


        const languagesNames = languages.map(
            (language: any) => language.name
        );
        const languagesString = languagesNames?.join(', ');

        return (
            <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                style={{
                    height: '400px',
                    width: '100%',
                }}
                onMouseLeave={this.handleMouseLeave}
                onMouseEnter={this.handleMouseEnter}
            >
                <Box sx={webStyle.div1Style}>
                    {is_promoted && <Promotecontent navigation="" id=""/>}
                    <div
                        style={{
                            ...webStyle.eventCardDiv,
                            boxShadow: isHover
                                ? 'inset 0px -137px 78px -44px rgba(255,182,54,0.33)'
                                : 'none',
                        }}
                    ></div>
                    <img
                        src={poster_url ?? ''}
                        alt="image"
                        style={webStyle.posterImgStyle}
                    />
                    <Fade in={isHover}>
                        <Box sx={webStyle.fadeStyle}>
                            <Box sx={webStyle.eventNameTxt}>{name}</Box>
                            <Box sx={webStyle.languagetxt}> {`${languagesString} (UA)`}</Box>
                            <Box sx={webStyle.categorytxt}>{show_type}</Box>
                            <Box sx={webStyle.BoxWrapper}>
                                <BookTicketBtn
                                    data-test-id="bookTicket"
                                    onClick={() => this.navigateToEventDetail(id)}
                                    fullWidth
                                >
                                    Book Tickets
                                </BookTicketBtn>
                            </Box>
                        </Box>
                    </Fade>
                </Box>
            </Grid>
        )
    }
}

export default (withStyles(styles)(AllEvent))
export { AllEvent }
// Customizable Area End
