// Customizable Area Start
import { MenuItem, Select, makeStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
    select: {
        position: "relative",
        width: (props: { elementWidth: number | string }) => props.elementWidth,
        fontFamily: "Poppins",
        fontWeight: 600,
        color: "#FFF",
        borderRadius: "8px",
        border: "solid 0.5px #ffb636",
        backgroundColor: "#2f2617",
        '& .MuiSelect-root': {
            padding: "12px 16px",
            zIndex: 2,
        },
        '&:before': {
            border: 'none',
        },
        '&:after': {
            border: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
            border: "none",
        },
    },
    icon: {
        color: "#ffb636",
        position: "absolute",
        right: "16px",
        zIndex: 1,
    },
    paper: {
        marginTop: "8px",
        fontFamily: "Poppins",
        width: (props: { elementWidth: number | string }) => props.elementWidth,
        fontSize: "1rem",
        color: "#FFF",
        padding: "0",
        maxHeight: "178px",
        backgroundColor: "#5a5a5a",
        border: "solid 1px #5a5a5a",
        boxShadow: "none",
        borderRadius: "10px",
        "& .MuiList-padding": {
            padding: "0",
        },
    },
    menuItem: {
        padding: '10px 16px',
        fontFamily: 'Poppins',
        fontSize: '1rem',
        color: "#FFF",
        '&:nth-child(odd)': {
            backgroundColor: '#5a5a5a',
        },
        '&:nth-child(even)': {
            backgroundColor: '#404040',
        },
    },
}))

export interface SelectOption {
    value: string | number,
    label: string,
}

interface SelectDropDownProps {
    value: string | number,
    options: SelectOption[],
    elementWidth: string | number,
    id?: string,
    handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void,
}

const SelectDropDown: React.FC<SelectDropDownProps> = ({ elementWidth, value, options, id, handleChange }) => {

    const classes = useStyles({ elementWidth })
    const [open, setOpen] = useState<boolean>(false)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Select
            id={`demo-simple-select-${id}`}
            data-test-id={id}
            value={value}
            className={classes.select}
            onChange={(event) => handleChange(event)}
            onOpen={handleOpen}
            onClose={handleClose}
            MenuProps={{
                classes: {
                    paper: classes.paper
                },
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
            }}
            IconComponent={() => open ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} />}
        >
            {options?.map(option => <MenuItem key={option.value} className={classes.menuItem} value={option.value}>{option.label}</MenuItem>)}
        </Select>
    )
}

export default SelectDropDown;
// Customizable Area End
