import React from "react";

// Customizable Area Start

import {
    Box, CircularProgress, Container, CssBaseline, Fade, Typography,
} from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { backarrowleft } from "../../ordermanagement/src/assets";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import ReactPlayer from "react-player";
import { playButton } from "../../reservations2/src/assets";

const theme = createTheme({
    palette: {
        primary: {
          main: "#ffb43a",
        },
        background: {
          default: "#121212",
          paper: "#404040"
        },
        text: {
          primary: "#FFF"
        }
      },
    typography: {
        h1: {
            fontSize: '24px',
            fontWeight: 600,
            fontFamily: "Poppins",
        },
        h2:{
            fontSize:20,
            fontWeight: 500,
            fontFamily: "Poppins",
        },
        h4:{
            fontSize:16,
            fontWeight:"normal",
            marginTop:"15px" ,
            fontFamily: "Poppins",
        },
        h6: {
            fontWeight: 500,
            fontFamily: "Poppins",
        },
        subtitle1: {
            margin: "20px 0px",
            fontFamily: "Poppins",
        },
    },
});

// Customizable Area End

import VogueDetailsController, {
    Props,
} from "./VogueDetailsController";

class VogueDetails extends VogueDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        const image =  this.state.loading ? [] : this.state.vogueDetail.data.attributes.files.filter((file) => file.match(/\.(jpg|jpeg|png|gif)$/i))
        
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <CssBaseline />

                <Box display="flex" minHeight="100vh" flexDirection="column">
                    <Box>
                        <MainHeaderComp navigation={this.props.navigation} />
                    </Box>

                    <Box flex={1}>

                        {
                            this.state.loading ? (
                                <Fade in={this.state.loading} unmountOnExit>
                                    <Box justifyContent="center" display="flex">
                                        <CircularProgress style={styles.circularProgress} />
                                    </Box>
                                </Fade>
                            ) : (
                                <>
                                    <Box data-test-id="cancel" className={classes.WrapperBoxs}>
                                        <Container>
                                            <Box className={classes.detailContainer}>
                                                <Box className={classes.topWrapperHeads} data-test-id="backContainer">
                                                    <img src={backarrowleft}
                                                        className={classes.backImageStyle}
                                                        alt="back"
                                                        onClick={this.handleBackBtn}
                                                        data-test-id="back"
                                                    />
                                                    <Typography component={'p'} variant="h1" data-test-id="head">Vogue</Typography>
                                                </Box>
                                                {(this.state.vogueDetail.data.attributes.youtube_links.length > 0 || image.length > 0) ? (
                                                    <Box className={classes.detailVideoContainer}>
                                                        {
                                                            this.state.vogueDetail.data.attributes.youtube_links.length > 0 ?
                                                                <ReactPlayer
                                                                    id="Trailer"
                                                                    url={`url=${this.state.vogueDetail.data.attributes.youtube_links[0].link}`}
                                                                    className={classes.reactPlayer}
                                                                    playing
                                                                    width='100%'
                                                                    height='100%'
                                                                    light={true}
                                                                    playIcon={<>{image.length > 0 && <img width={"100%"} height={"100%"} src={image && image[0]} />}<img className={classes.ReactPlayerBackImg} src={playButton} alt="play" /></>}
                                                                />
                                                                : <img width={"100%"} height={"100%"} src={image && image[0]} />
                                                        }
                                                    </Box>
                                                ) : ""}
                                                <Typography variant="h2">{this.state.vogueDetail.data.attributes.title}</Typography>
                                                <Typography variant="h4">{this.state.vogueDetail.data.attributes.description}</Typography>
                                                <Box className={classes.displayFlex}>
                                                    {
                                                        this.state.vogueDetail.data.attributes.files
                                                            .filter((file) => file.match(/\.(jpg|jpeg|png|gif)$/i))
                                                            .slice(0, 2)
                                                            .map((file) => <img src={file} className={classes.imageStyle} alt="assets" />)
                                                    }
                                                </Box>
                                                <Box className={classes.displayFlex}>
                                                    {this.state.vogueDetail.data.attributes.hash_tags.map((hashTag) => <span className={classes.categoryBtn}>#{hashTag.name}</span>)}
                                                </Box>
                                            </Box>
                                        </Container>
                                    </Box>
                                </>
                            )
                        }

                    </Box>

                    <Box>
                        <FooterWithEvents />
                    </Box>

                </Box>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = {
    circularProgress: {
        margin: "20px auto",
        color: "#ffb43a"
    },
    WrapperBoxs: {
        display: 'flex',
        margin: '12px 9px 9px 0px',
        justifyContent: 'space-between',
        fontSize: '18px',
        fontFamily: 'Poppins',
        color: '#fff',
    },
    topWrapperHeads: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '18px',
        marginBottom: '22px',
        gridGap: '15px',
    },
    backImageStyle: {
        height: '32px',
        width: '32px',
        cursor: 'pointer' 
    },
    detailContainer:{
        width:"100%", 
        maxWidth:"734px", 
    },
    detailVideoContainer:{
        width:"100%",
        position: 'relative' as const,
        height:312,
        marginBottom:16,
        aspectRatio: "1.94",
        borderRadius: "6px",
        overflow: "hidden" as const,
        ".react-player__preview": {
            backgroundPosition: 'top center !important'
        }
    },
    displayFlex:{
        display:"flex",
        flexWrap:"wrap" as const
    },
    imageStyle: {
        maxWidth: "343px",
        margin:"16px 16px 0px 0px",
        width: "100%",
        height: "198px",
        borderRadius: "6px",
        '@media(max-width:500px)': {
            marginRight:0,
        },
    },
    categoryBtn: {
        border: "solid 1px #b4b4b4",
        padding: "8px 16px",
        borderRadius: "12px",
        backgroundColor: "#2f2617",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "normal" as const,
        textTransform:"none" as const,
        color: "#ffb43a",
        marginRight:16,
        marginTop:16,
        '&:hover': {
            backgroundColor: "#2f2617",
        }
    },
    reactPlayer: {
        "& .react-player__preview": {
            backgroundPosition: 'top center !important',
            backgroundRepeat: 'no-repeat',
        }
    },
    ReactPlayerBackImg:{
        height:50,
        width:50,
        position: 'absolute' as const,
    }
}

export default (withStyles(styles)(VogueDetails))
export { VogueDetails }
// Customizable Area End
