import { Box, Button, Dialog, DialogContent, DialogProps, makeStyles, styled } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { EventListenerKeys, LocalStorageKeys } from './enums.web';
import { getStorageData } from '../../framework/src/Utilities';

type IPushNotificationProps = {

}

const useStyles = makeStyles(({
  DialogPaper: {
    fontFamily: "Poppins",
    backgroundColor: "#404040",
    boxShadow: 'none',
    borderRadius: "8px",
    boxSizing:"border-box",
    position: 'absolute',
    top: '20px',
    right: '20px',
    margin: 0,
    color: "#FFF",
    "& .MuiDialogContent-root": {
      fontWeight: 600,
      padding: "20px !important"
    }
  },
  BackDrop: {
    backdropFilter: "blur(2px)"
  },
}));

const RFHButton = styled(Button)({
  fontFamily: "Poppins",
  color: "#000",
  backgroundColor: "#ffb43a",
  padding: "6px 22px",
  borderRadius: "8px",
  textTransform: "capitalize",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#ffb43a",
    color: "#000"
  },
  "&.MuiButton-outlined": {
    backgroundColor: "transparent",
    border: "solid 1px #ffb43a",
    color: "#fff"
  }
})

const PushNotificationPermission: React.FC<IPushNotificationProps> = () => {

  const classes = useStyles()
  const [open, setOpen] = useState(('Notification' in window && Notification.permission === 'default' ? true : false))
  const [loggedInUser, setLoggedInUser] = useState(false)

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    closeDialog()
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const handleSignUp = () => {
    closeDialog()
    window.dispatchEvent(new Event(EventListenerKeys.OpenSignUp))
  }

  const handleAllowNotification = () => {
    closeDialog()
    window.dispatchEvent(new Event(EventListenerKeys.GenerateFCMToken))
  }

  useEffect(() => {
    getStorageData(LocalStorageKeys.LoginToken).then((token) => {
      if (token) setLoggedInUser(true)
      else setLoggedInUser(false)
    })
  })

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.DialogPaper }}
        BackdropProps={{ className: classes.BackDrop }}

      >
        <DialogContent>
          <Box>
            Would you like to receive Push Notifications?
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end" gridGap="12px">
            <RFHButton variant='outlined' onClick={closeDialog}>No, Thanks</RFHButton>
            {
              loggedInUser ? (
                <RFHButton onClick={handleAllowNotification}>Allow</RFHButton>
              ) : (
                <RFHButton onClick={handleSignUp}>Yes, SignUp!</RFHButton>
              )
            }
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PushNotificationPermission