import React from "react";

import {
    Grid,
    // Customizable Area Start
    Fade,
    Button,
    Popover,
    Typography,
    Checkbox,
    Container,
    Box,
    Tooltip,
    IconButton
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { check, checkboxicon, resetImg, starFilled, starOutline } from './assets'
import { styled, withStyles } from "@material-ui/core/styles";
import Ratings from "../../reviews/src/Ratings.web";
export const configJSON = require("./config");
import UserSignUp from "../../social-media-account-registration/src/UserSignUp.web";
import Promotecontent from "../../promotecontent/src/Promotecontent.web";

const Dropdownbtn = styled(Button)(({ theme }) => ({
    padding: "10px 12px",
    border: "solid 0.5px #ffb636",
    borderRadius: "8px",
    backgroundColor: "#2f2617",
    color: "#ffffff",
    '&:hover': {
        backgroundColor: "#2f2617"
    },
    textTransform: "capitalize",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 600
}));

const BookTicketBtn = styled(Button)({
    backgroundColor: "#121212",
    padding: "12px",
    textAlign: "center",
    maxHeight: "40px",
    color: "#fff",
    border: "0.5px solid #FFB636",
    borderRadius: "4px",
    fontFamily: "Poppins",
    textTransform: "capitalize",
    position: "relative",
    zIndex: 10,
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#FFB636",
        color: "#000"
    }
})

const styles = {
    tooltip: {
        backgroundColor: '#121212',
    },
    arrow: {
        color: "#121212",
    }
};

export const PlanPremiumIcon = ({ ...other }) => {
    return <img src={checkboxicon} alt="images" width="20px" />;
}

export const PlanPremiumIcon2 = ({ ...other }) => {
    return <img src={check} alt="images" width="20px" />;
}

const WhiteBackgroundCheckboxUnchecked = withStyles({
    root: {
        color: "#ffb43a",
        '&$checked': {
            color: '#ffb43a',
        },
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',
            left: 4,
            height: 15,
            width: 15,
            position: "absolute",
            top: 4,
            zIndex: -1
        },
        padding: '0px',
        marginRight: "8px"
    },
    checked: {}
})(Checkbox);

const CustomTooltip = withStyles(styles)(Tooltip);
// Customizable Area End

import SeeAllMoviesController, {
    Props,
} from "./SeeAllMoviesController.web";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { AddFavourites } from "../../favourites/src/AddFavourites.web";
import { filled_heart, heart } from "../../splashscreen/src/assets";
import SelectDropDown from "../../../components/src/SelectDropdown.web";



export default class SeeAllMovies extends SeeAllMoviesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <div style={webStyle.bgcolor}>
                    <MainHeaderComp navigation = {this.props.navigation} />
                    <Container maxWidth="lg">
                        <Grid container spacing={2} style={webStyle.main}>
                                <Grid item style={webStyle.filterDropDownWrapper}>

                                <SelectionWrapper>
                                    <SelectDropDown
                                        data-test-id="status-filter"
                                        id="status"
                                        value={this.state.selectedStatusOption}
                                        options={this.state.statusOptions}
                                        elementWidth="170px"
                                        handleChange={this.handleStatusChange}
                                    />
                                </SelectionWrapper>

                                    {this.state.lstMenu.map((obj) => {
                                        return (
                                            <MenuButton key={obj.title} obj={obj} isChecked={this.isChecked} setSearch={this.setSearch} lstSearch={this.state.lstSearch} getCount={this.getCount} />
                                        )
                                    })}

                                </Grid>
                                <Grid item style={webStyle.resetButtonWrapper}>
                                    <div style={webStyle.resetstyle} data-test-id="resetFilterClick" onClick={() => this.resetFilter()}>
                                        <img src={resetImg} style={{ height: "20px", width: '20px', marginRight: '8px' }} alt="resetImg" />Reset
                                    </div>
                                </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            {
                                (this.isFilterApplyed() ? this.state.filterList : this.state.movieList || []).map((movie) => {
                                    return (
                                        <MovieCard objMovie={movie} navigation={this.props.navigation} id={""} />
                                    )
                                })
                            }
                        </Grid>
                    </Container>
                    <FooterWithEvents />
                </div >
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    BoxWrapper: {
        padding: "10px"
    },
    IconWrapper: {
        padding: 0
    },
    bgcolor: { backgroundColor: "#121212" },
    downarrow: {
        color: '#ffb43a'
    },
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    lightListStyle: {
        display: "flex",
        backgroundColor: "#5a5a5a",
        padding: '10px',
        minWidth: "100px"
    },
    darkListStyle: {
        display: "flex",
        backgroundColor: "#404040",
        padding: '10px',
        minWidth: "100px"
    },
    listtxt: {
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        fontFamily: "Poppins"
    },
    resetstyle: {
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontFamily: 'Poppins',
        cursor: 'pointer'
    },
    categorytxt: {
        color: "#fff",
        margin: "10px",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: "bold"
    },
    movieNameTxt: {
        color: "#fff",
        margin: "10px",
        fontWeight: "bold",
        fontFamily: "Poppins",
        fontSize: "16px"
    },
    bookTicketbtn: {
        backgroundColor: "#121212",
        margin: "10px",
        padding: "10px",
        textAlign: "center",
        color: "#fff",
        border: "0.5px solid #FFB636",
        borderRadius: "4px",
        fontFamily: "Poppins",
        textTransform: "capitalize",
        position: "relative",
        zIndex: 10,
        cursor: "pointer",
    },
    languagetxt: {
        color: "#fff",
        margin: "10px",
        fontFamily: "Poppins",
        fontSize: "12px",
    },
    main: {
        display: "flex",
        justifyContent: "space-between",
        padding: '20px 0'
    },
    filterDropDownWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        flexWrap: "wrap" as const
    },
    resetButtonWrapper: {
        display: "flex",
        alignItems: "center",
    },
    paperProps: {
        borderRadius: '12px',
        marginTop: "12px",
        width: "190px",
        maxHeight: "220px"
    },
    div1Style: {
        height: "100%",
        width: "100%",
        border: "2px solid #FFB636",
        position: "relative",
    },
    fadeStyle: {
        position: "absolute",
        bottom: "10px",
        width: "100%",
        transition: "0.8s ease-in-out",
    },
    topRightIcons: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        right: "8px",
        top: "8px",
        backgroundColor: "rgb(0,0,0,0.7)",
        padding: "8px",
        zIndex: 2,
    },
    starIconStyle: {
        color: "#FFB636",
        margin: "15px 0px"
    },
    btnRateStyle: {
        height: '38px',
        width: '107px',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        border: ' solid 1px #ffb43a',
        padding: '10px 18px',
        boxSizing: 'border-box' as const,
        borderRadius: '8px',
        color: '#ffb43a',
        cursor: 'pointer',
        lineHeight: 1,
        textAlign: 'center' as const
    },
    boxRatingTitle: {
        fontSize: '18px',
        fontWeight: 'bold' as const,
        color: '#fffcfc',
        fontFamily: 'Poppins',
    },
    boxRatingSubTitle: {
        marginTop: '6px',
        fontSize: '14px',
        color: '#b4b4b4',
        fontFamily: 'Poppins',
    },

};

export class MovieCard extends SeeAllMovies {
    constructor(props: any) {
        super(props);
    }

    handleMouseEnter = () => {
        this.setState({ isHover: true });
    };

    handleMouseLeave = () => {
        this.setState({ isHover: false });
    };

    render() {

        const { objMovie }: any = this.props;
        const { isHover } = this.state;

        const languageNames = objMovie?.attributes?.languages.map(
            (language: any) => language.language_name
        );
        const languageString = languageNames?.join(', ');

        const addFavourites = {
            heartIcon: heart,
            filledHeart: filled_heart,
            imageStyle: {
                width: "20px",
                height: "18px",
                cursor: "pointer"
            },
            favouriteable_id: objMovie?.id,
            favouriteable_type: "BxBlockCategories::Movie",
            tooltipDirection: "left-end",
            isFavourite: objMovie?.attributes.is_favourite
        }

        const ratingsItems = {
            movieId: this.state.getMovie,
            bannerImage: this.state.dummyData?.attributes?.image_url,
            movieName: this.state.dummyData?.attributes,
            buttonStyle: webStyle.btnRateStyle,
            buttonText: configJSON.btnRateNow,
            boxRatingTitle: webStyle.boxRatingTitle,
            buttonLabel: configJSON.labelAddRating,
            boxRatingSubTitle: webStyle.boxRatingSubTitle,
            labelFeelFree: configJSON.labelFeelFree,
        }

        return (
            <Grid
                item
                xs={12}
                sm={4}
                md={3}
                style={{
                    height: '400px',
                    width: '100%',
                }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onTouchStart={this.handleMouseEnter}
            >
                <Box component={'div'} sx={webStyle.div1Style}>
                {objMovie?.attributes.is_promoted && <Promotecontent navigation="" id=""/>}
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            position: 'relative',
                            zIndex: 1,
                            boxShadow: isHover
                                ? 'inset 0px -137px 78px -44px rgba(255,182,54,0.33)'
                                : 'none',
                        }}
                    ></div>
                    <img
                        src={objMovie?.attributes?.image_url ? objMovie.attributes.image_url : ''}
                        alt="image"
                        style={{
                            objectFit: 'fill',
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                        }}
                    />
                    <Box component={'div'} sx={webStyle.topRightIcons}>
                        <AddFavourites addFavourites={addFavourites} navigation={""} id={""} />
                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <img data-test-id="starbuttonClick" src={objMovie?.attributes?.is_reviewed ? starFilled : starOutline} alt="" style={{ width: '22px', height: '22px', margin: 0 }} onClick={() => this.handleOpenDialog(objMovie?.id)} />

                        </div>
                        {this.state.Ratingpage &&
                            <Ratings navigation={this.props.navigation} id={""}
                                ratingsData={ratingsItems} />}
                        {this.state.doSignUp &&
                            <UserSignUp
                                data-test-id="closestarbuttonClick"
                                navigation={undefined}
                                id="testId"
                            />}
                        <CustomTooltip
                            title="Play Trailer"
                            arrow
                            placement="left"
                        >
                            <IconButton data-test-id="click-btn" style={webStyle.IconWrapper} onClick={() => this.redirectToTrailers(objMovie?.id)}>
                                <PlayCircleFilledWhiteIcon style={{ color: '#FFB636', zIndex: 9999 }} />
                            </IconButton>
                        </CustomTooltip>
                    </Box>
                    <Fade in={isHover}>
                        <Box component={'div'} sx={webStyle.fadeStyle}>
                            <Box component={'div'} sx={webStyle.movieNameTxt}>
                                {objMovie?.attributes?.name}
                            </Box>
                            <Box component={'div'} sx={webStyle.languagetxt}>
                                {`${languageString} (UA)`}
                            </Box>
                            <Box component={'div'} sx={webStyle.categorytxt}>
                                {objMovie?.attributes?.category_type}
                            </Box>
                            <Box sx={webStyle.BoxWrapper}>
                                <BookTicketBtn fullWidth onClick={() => this.getMoviedetails(objMovie?.id)}>
                                    Book Tickets
                                </BookTicketBtn>
                            </Box>
                        </Box>
                    </Fade>
                </Box>
            </Grid>
        );
    }
}

export const MenuButton = ({ obj, isChecked, setSearch, getCount }: any) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>

            <Dropdownbtn onClick={handleClick}>
                {obj.title}
                {getCount(obj.title) ?
                    <span>
                        {`(${getCount(obj.title)})`}
                    </span>
                    : ''}

                {open ? (
                    <ExpandLessIcon style={webStyle.downarrow} />
                ) : (
                    <KeyboardArrowDownIcon style={webStyle.downarrow} />
                )}
            </Dropdownbtn>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                    style: webStyle.paperProps,
                }}
                data-test-id="anchorbtn"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >{obj?.options?.map((list: any, index: number) => {
                return (
                    <div style={index % 2 == 0 ? webStyle.lightListStyle : webStyle.darkListStyle}>
                        {obj.isCheckBox &&
                            <>
                                <WhiteBackgroundCheckboxUnchecked
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    data-test-id="checkboxt"
                                    checked={isChecked(list, obj.title)}
                                    onChange={() => {
                                        setSearch(list, obj.title)
                                    }}
                                    checkedIcon={<PlanPremiumIcon />}
                                    icon={<PlanPremiumIcon2 />}
                                />
                            </>
                        }
                        <Typography style={webStyle.listtxt}>{list}</Typography>
                    </div>
                )
            })}</Popover>
        </>
    )
}

const SelectionWrapper = styled(Box)({
    "& .MuiSelect-root": {
        fontSize: "12px"
    }
})


// Customizable Area End