import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { DetailsType, EventListenerKeys, HomePageFilterOption, KycStatus, LocalStorageKeys } from "../../../components/src/enums.web";
import { IUserDetails } from "../../../components/src/interfaces.web";
import { callApi } from "../../../components/src/Toolkit";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPlayList: any;
  citiesList: any;
  allCitiesList: any;
  token: string;
  openSignup: boolean;
  openKycPendingDialog: boolean;
  location: string;
  allEventITOList: any
  eventList: any;
  isHover: any;
  isCurrentlyPlayingLoading: boolean
  isEventsLoading: boolean
  isItoListingLoading: boolean
  selectedFilter: HomePageFilterOption
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class HomepageController extends BlockComponent<
  Props,
  S,
  SS
>
{
  // Customizable Area Start
  apiCurrentPlayCallId: string = "";
  getCitiesApiCallId: string = "";
  getAllCitiesApiCallId: string = "";
  getAccountDetails: string = "";
  getAllEventApiCall: string = "";
  getItoListApiCallId: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      currentPlayList: [],
      citiesList: [],
      allCitiesList: [],
      token: '',
      openSignup: false,
      openKycPendingDialog: false,
      location: '',
      allEventITOList: [],
      eventList: [],
      isHover: {},
      isCurrentlyPlayingLoading: true,
      isEventsLoading: true,
      isItoListingLoading: true,
      selectedFilter: HomePageFilterOption.CurrentlyPlaying
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson) {
        switch (apiRequestCallId) {

          case this.apiCurrentPlayCallId:
            this.setState({ currentPlayList: responseJson.data, isCurrentlyPlayingLoading: false })
            break;

          case this.getItoListApiCallId:
            this.setState({ allEventITOList: responseJson.data, isItoListingLoading: false })
            break;

          case this.getCitiesApiCallId:
            this.setState({ citiesList: responseJson.data });
            await setStorageData('citiesList', JSON.stringify(responseJson.data));
            window.dispatchEvent(new Event('CustomCityList'));
            break;

          case this.getAllCitiesApiCallId:
            this.setState({ allCitiesList: responseJson.data });
            await setStorageData('allCitiesList', JSON.stringify(responseJson.data));
            window.dispatchEvent(new Event('CustomCityList'));
            break;

          case this.getAccountDetails:
            this.handleAccountDetails(responseJson)
            break;

          case this.getAllEventApiCall:
            this.setState({ eventList: responseJson.data, isEventsLoading: false })
            break;

        }
      }

    }
    // Customizable Area End
  }

  getUserDetails = async () => {
    const userToken = (await getStorageData(LocalStorageKeys.LoginToken) as string);
    this.setState({ token: userToken });
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    const location = (await getStorageData(LocalStorageKeys.Location) as string);
    const userToken = (await getStorageData(LocalStorageKeys.LoginToken) as string);

    this.setState({ token: userToken, location: location }, () => {
      this.getCurrentPlayList();
      this.getCitiesList();
      this.getAllCitiesList();
      this.getAccountInfo();
      this.getItoListing();
      this.getEventList();
      this.getUserDetails();
      window.scrollTo(0, 0)
    })

    window.addEventListener(EventListenerKeys.UserDetails, this.getUserDetails)
    window.addEventListener(EventListenerKeys.ProfileUpdated, this.handleLocationChange)
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    window.removeEventListener(EventListenerKeys.UserDetails, this.getUserDetails);
    window.removeEventListener(EventListenerKeys.ProfileUpdated, this.handleLocationChange);
  }

  handleLocationChange = () => {
    this.setState({ isItoListingLoading: true, isEventsLoading: true, isCurrentlyPlayingLoading: true})
    this.getItoListing();this.getCurrentPlayList();this.getEventList();
  }

  handleAccountDetails = async (resJson: any) => {
    setStorageData(LocalStorageKeys.UserDetails, JSON.stringify(resJson.data as IUserDetails))

    resJson.data.attributes?.user_city?.name && await setStorageData('location', resJson.data.attributes.user_city.name);
    resJson.data.attributes.full_name && await setStorageData("userName", resJson.data.attributes.full_name);
    resJson.data.attributes.profile_image
      ? await setStorageData("profilePic", resJson.data.attributes.profile_image)
      : removeStorageData("profilePic");
    window.dispatchEvent(new Event('customUserDetails'));
  }

  getAccountInfo = () => {

    if(!this.state.token) return; 

    const header = {
      "Content-Type": 'application/json',
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccountDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    this.state.token && runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ///Menu api request start///
  getCurrentPlayList = async (filterOption = HomePageFilterOption.CurrentlyPlaying) => {

    this.setState({ selectedFilter: filterOption, isCurrentlyPlayingLoading: true })

    const location = (await getStorageData(LocalStorageKeys.Location)) as string;

    let endpoint = configJSON.menuApiCallUrl;
    
    if(location) endpoint = endpoint + `location=${location}`;
    if(filterOption === HomePageFilterOption.ThisFriday || filterOption === HomePageFilterOption.ComingSoon) {
      endpoint = endpoint + `&page=${filterOption}`;
    }else {
      endpoint = endpoint + '&status=playing'
    }

    this.apiCurrentPlayCallId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.apiGetMethodType,
      endPoint: endpoint,
      headers: { "token": this.state.token || "guest_user" },
    }, runEngine)
  }

  ///Menu api request end///

  getItoListing = async () => {
    const location = await getStorageData(LocalStorageKeys.Location) as string;

    let endpoint = `${configJSON.getMovieEventItoListing}`;
    if (location) endpoint = endpoint.concat(`?location=${location}`);

    this.getItoListApiCallId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.apiGetMethodType,
      endPoint: endpoint,
      headers: { "token": this.state.token || "guest_user" },
    }, runEngine)
  }

  redirectToItoListing = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ItoListing");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  // -----------------------GET LOCATION functions------------------------------------------
  ///cities api request start///
  getCitiesList = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCitiesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.citiesApiCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ///cities api request end///

  /// MORE cities LIST request start///

  getAllCitiesList = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCitiesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allCitiesApiCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ///MORE cities LIST api request end///

  getEventList = async () => {
    const location = (await getStorageData(LocalStorageKeys.Location)) as string;

    let endpoint = configJSON.getAllEventEndPoint;
    if(location) {
      endpoint = endpoint + `?location=${location}`;
    }

    this.getAllEventApiCall = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.apiGetMethodType,
      endPoint: `${endpoint}`,
      headers: { "token": this.state.token || configJSON.guestToken },
    }, runEngine)
  }

  handleMouseEnter = (id: any) => {
    this.setState({ isHover: { [id]: true } });
  };

  handleMouseLeave = (id: any) => {
    this.setState({ isHover: { [id]: false } });
  };

  navigateToEventDetail = (eventId: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EventDetail");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), eventId);
    this.send(message);
  }

  navigateToMovieItoDetail = (movieId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AuctionBidding");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), movieId);
    this.send(message);
  }

  handleItoRedirection = async (bookingId: string, type: string) => {
    if (this.state.token) {

      const userDetails = (await getStorageData(LocalStorageKeys.UserDetails, true) as IUserDetails);
      if (userDetails.attributes.kyc_status !== KycStatus.Approved) {
        this.setState({ openKycPendingDialog: true })
        return
      }
      if (type == DetailsType.Event) {
        this.navigateToEventDetail(bookingId)
      } else {
        this.navigateToMovieItoDetail(bookingId)
      }
    } else {
      this.setState({ openSignup: true })
    }
  }

  closeSignUp = () => this.setState({ openSignup: false })
  closeKycPopup = () => this.setState({ openKycPendingDialog: false })

  navigateToMovieDetails = (movieId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "MovieDetail");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), movieId);
    this.send(message);
  }

  // Customizable Area End
}

