// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfwhatsappintegration64 from "../../blocks/cfwhatsappintegration64/src/Cfwhatsappintegration64";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Homepage from "../../blocks/splashscreen/src/Homepage.web";
import BookingHistory from "../../blocks/ordermanagement/src/BookingHistoryView.web";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Cfaskingforacceptingtickets from "../../blocks/cfaskingforacceptingtickets/src/Cfaskingforacceptingtickets";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Customform3 from "../../blocks/customform3/src/Customform3";
import Inventorymanagementintegration2 from "../../blocks/inventorymanagementintegration2/src/Inventorymanagementintegration2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import AboutUs from "../../blocks/contentmanagement3/src/AboutUs.web";
import AboutInvestor from "../../blocks/contentmanagement3/src/AboutInvestor.web";
import AboutTeam from "../../blocks/contentmanagement3/src/AboutTeam.web";
import Admanager from "../../blocks/admanager/src/Admanager";
import Itemavailability from "../../blocks/itemavailability/src/Itemavailability";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Productrecommendationengine from "../../blocks/productrecommendationengine/src/Productrecommendationengine";
import Notifications from "../../blocks/notifications/src/Notifications";
import Promotecontent from "../../blocks/promotecontent/src/Promotecontent";
import Location from "../../blocks/location/src/Location";
import Splitpayments2 from "../../blocks/splitpayments2/src/Splitpayments2";
import Refundmanagement from "../../blocks/refundmanagement/src/Refundmanagement";
import Cfcountdowntimer2 from "../../blocks/cfcountdowntimer2/src/Cfcountdowntimer2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Videoembeddingyoutube from "../../blocks/videoembeddingyoutube/src/Videoembeddingyoutube";
import Contactus from "../../blocks/contactus/src/Contactus";
import ContactusScreen from "../../blocks/contactus/src/ContactUsScreen.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Referrals from "../../blocks/referrals/src/Referrals.web";
import Cfqrcodegenerator17 from "../../blocks/cfqrcodegenerator17/src/Cfqrcodegenerator17";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Share from "../../blocks/share/src/Share";
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import Favourites from "../../blocks/favourites/src/Favourites.web";
import AddFavourites from "../../blocks/favourites/src/AddFavourites.web";
import AuctionBidding from "../../blocks/auctionbidding/src/AuctionBidding.web";
import AuctionBiddingDetail from "../../blocks/auctionbidding/src/AuctionBiddingDetail.web";
import AuctionBiddingAuditorium from "../../blocks/auctionbidding/src/AuctionBiddingNotification.web";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement.web";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Videos from "../../blocks/videos/src/Videos";
import Centralisedbilling from "../../blocks/centralisedbilling/src/Centralisedbilling";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Reservations2 from "../../blocks/reservations2/src/Reservations2";
import MovieDetails from "../../blocks/reservations2/src/MovieDetails.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Cfrazorpay16 from "../../blocks/cfrazorpay16/src/Cfrazorpay16";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Trending2 from "../../blocks/trending2/src/Trending2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Customadvertisements from "../../blocks/customadvertisements/src/Customadvertisements";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfwallet19 from "../../blocks/cfwallet19/src/Cfwallet19.web";
import AddMoney from "../../blocks/cfwallet19/src/AddMoney.web";
import AccountDetails from "../../blocks/cfwallet19/src/AccountDetails.web";
import BankAccountsList from "../../blocks/cfwallet19/src/BankAccountsList.web";
import PaymentInfo from "../../blocks/cfwallet19/src/PaymentInfo.web";
import Maps from "../../blocks/maps/src/Maps";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Trending from "../../blocks/trending/src/Trending";
import UserSignUp from "../../blocks/social-media-account-registration/src/UserSignUp.web";
import RegularBooking from "../../blocks/reservations2/src/RegularBooking.web"
import SeeAllMovies from "../../blocks/reservations2/src/SeeAllMovies.web"
import BookingSuccessful from "../../blocks/reservations2/src/BookingSuccessful.web"
import PaymentSelection from "../../blocks/cfwallet19/src/PaymentSelection.web"
import FoodAndBeverages from "../../blocks/reservations2/src/FoodAndBeverages.web"
import Trailers from "../../blocks/reservations2/src/Trailers.web"
import SeatSelection from "../../blocks/reservations2/src/SeatSelection.web"
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy";
import TermsAndConditions from "../../blocks/termsconditions/src/TermsAndConditions.web";
import EditUserProfile from "../../blocks/user-profile-basic/src/EditUserProfile.web";
import RatingsReviews from "../../blocks/reviews/src/RatingsReviews.web";
import ElectronicTicket from "../../blocks/reservations2/src/ElectronicTicket.web"
import ListToSell from "../../blocks/ordermanagement/src/ListToSell.web";
import Ratings from "../../blocks/reviews/src/Ratings.web";
import ViewTicket from "../../blocks/ordermanagement/src/ViewTicket.web";
import Request from "../../blocks/requestmanagement/src/RequestManagement.web"
import CancelBooking from "../../blocks/ordermanagement/src/CancelBooking.web";

import AddEventDetail from "../../blocks/events/src/AddEventDetailScreen.web"
import ConfirmPayment from "../../blocks/requestmanagement/src/ConfirmPayment.web";
import VogueList from "../../blocks/categoriessubcategories/src/VogueList.web"
import VogueDetails from "../../blocks/categoriessubcategories/src/VogueDetails.web"

import LiveRequest from "../../blocks/requestmanagement/src/LiveRequest.web";
import AllEvent from "../../blocks/events/src/AllEventScreen.web"
import EventDetail from "../../blocks/events/src/EventDetailScreen.web"
import SelectVenue from "../../blocks/events/src/SelectVenueScreen.web"
import SelectTier from "../../blocks/events/src/SelectTier.web"
import ListedTicket from "../../blocks/events/src/ListedTicket.web"
import EventTrailer from "../../blocks/events/src/EventTrailerScreen.web"
import TransactionHistory from '../../blocks/cfwallet19/src/TransactionHistory.web';
import EventViewTicket from "../../blocks/ordermanagement/src/EventViewTicket.web";
import EventListToSell from "../../blocks/ordermanagement/src/EventListToSell.web";
import ITOBookingHistory from "../../blocks/ordermanagement/src/ITOBookingHistory.web";
import ItoListing from "../../blocks/events/src/ItoListing.web";
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import { EventListenerKeys, LocalStorageKeys } from "../../components/src/enums.web";
import PushNotificationPermission from "../../components/src/PushNotificationPermission.tsx";
import { callApi } from "../../components/src/Toolkit";
import { runEngine } from "../../framework/src/RunEngine";

const routeMap = {
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
Trending:{
 component:Trending,
path:"/Trending"},

Cfwhatsappintegration64:{
 component:Cfwhatsappintegration64,
path:"/Cfwhatsappintegration64"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Homepage:{
  component:Homepage,
 path:"/Homepage"},
  BookingHistory: {
    component: BookingHistory,
    path: "/bookinghistory"
  },
ListToSell:{
  component:ListToSell,
 path:"/ListToSell/:navigationBarTitleText"},
 CancelBooking:{
  component:CancelBooking,
 path:"/CancelBooking/:navigationBarTitleText"},
 ViewTicket:{
  component:ViewTicket,
 path:"/ViewTicket/:navigationBarTitleText"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Cfaskingforacceptingtickets:{
 component:Cfaskingforacceptingtickets,
path:"/Cfaskingforacceptingtickets"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Customform3:{
 component:Customform3,
path:"/Customform3"},
Inventorymanagementintegration2:{
 component:Inventorymanagementintegration2,
path:"/Inventorymanagementintegration2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
AboutUs:{
  component:AboutUs,
 path:"/AboutUs"},
 AboutInvestor:{
  component:AboutInvestor,
 path:"/AboutInvestor"},
 AboutTeam:{
  component:AboutTeam,
 path:"/AboutTeam"},
Admanager:{
 component:Admanager,
path:"/Admanager"},
Itemavailability:{
 component:Itemavailability,
path:"/Itemavailability"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Productrecommendationengine:{
 component:Productrecommendationengine,
path:"/Productrecommendationengine"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Promotecontent:{
 component:Promotecontent,
path:"/Promotecontent"},
Location:{
 component:Location,
path:"/Location"},
Splitpayments2:{
 component:Splitpayments2,
path:"/Splitpayments2"},
Refundmanagement:{
 component:Refundmanagement,
path:"/Refundmanagement"},
Cfcountdowntimer2:{
 component:Cfcountdowntimer2,
path:"/Cfcountdowntimer2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Videoembeddingyoutube:{
 component:Videoembeddingyoutube,
path:"/Videoembeddingyoutube"},
Contactus:{
 component:ContactusScreen,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Referrals:{
 component:Referrals,
path:"/Referrals"},
Cfqrcodegenerator17:{
 component:Cfqrcodegenerator17,
path:"/Cfqrcodegenerator17"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Share:{
 component:Share,
path:"/Share"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
Favourites:{
 component:Favourites,
path:"/favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
  AuctionBidding: {
    component: AuctionBidding,
    path: "/AuctionBidding/:navigationBarTitleText"
  },
  AuctionBiddingDetail: {
    component: AuctionBiddingDetail,
    path: "/AuctionBiddingDetail/:navigationBarTitleText"
  },
  AuctionBiddingAuditorium: {
    component: AuctionBiddingAuditorium,
    path: "/AuctionBiddingAuditorium/:navigationBarTitleText"
  },
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
RequestManagement:{
  component:RequestManagement,
 path:"/request"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Videos:{
 component:Videos,
path:"/Videos"},
Centralisedbilling:{
 component:Centralisedbilling,
path:"/Centralisedbilling"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Reservations2:{
 component:Reservations2,
path:"/Reservations2"},
RegularBooking:{
  component: RegularBooking,
 path:"/RegularBooking/:id"},
 SeatSelection: {
  component:SeatSelection,
  path:"/SeatSelection/:navigationBarTitleText"
},
 PaymentSelection:{
  component: PaymentSelection,
  path: "/PaymentSelection"
 },
AddMoney: {
 component: AddMoney,
 path: "/addMoney/:navigationBarTitleText"
},
AccountDetails: {
  component: AccountDetails,
  path: "/accountDetails"
},
BankAccountsList:{
  component: BankAccountsList,
  path: "/bankAccountsList"
},
PaymentInfo:{
  component: PaymentInfo,
  path: "/paymentInfo/:navigationBarTitleText"
},
MovieDetails:{
  component:MovieDetails,
  path:"/MovieDetails/:id",
},
MovieDetail:{
  component:MovieDetails,
  path:"/MovieDetail/:navigationBarTitleText",
},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
PrivacyPolicy:{
  component:PrivacyPolicy,
  path:"/privacypolicy"
},
TermsAndConditions:{
  component:TermsAndConditions,
  path:"/TermsAndConditions"
},
EditUserProfile:{
  component:EditUserProfile,
  path:"/edituserprofile"
},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Cfrazorpay16:{
 component:Cfrazorpay16,
path:"/Cfrazorpay16"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Trending2:{
 component:Trending2,
path:"/Trending2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Customadvertisements:{
 component:Customadvertisements,
path:"/Customadvertisements"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cfwallet19:{
 component:Cfwallet19,
path:"/Cfwallet19"},
Cfwallet19Params:{
  component:Cfwallet19,
 path:"/wallet19/:id"},
Maps:{
 component:Maps,
path:"/Maps"},
SeeAllMovies:{
  component:SeeAllMovies,
  path:"/seeall"
},
RatingsReviews:{
  component:RatingsReviews,
  path:"/RatingsReviews/:id"
},
Ratings:{
  component:Ratings,
  path:"/Ratings"
},
BookingSuccessful:{
  component: BookingSuccessful,
  path: "/BookingSuccessful/:id",
},
BookingSuccessfully:{
  component: BookingSuccessful,
  path: "/BookingSuccessfully",
},
FoodAndBeverages:{
  component: FoodAndBeverages,
  path: "/FoodAndBeverages",
},
Trailers:{
  component:Trailers,
  path:"/Trailers/:navigationBarTitleText"
},

Home: {
  component:Homepage,
      path: '/',
      exact: true
    },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  UserSignup: {
    component: UserSignUp,
    path: '/signup'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  ElectronicTicket: {
    component: ElectronicTicket,
    path: "/m/:id"
  },
  AddEventDetail:{
    component: AddEventDetail,
    path: "/addEventDetail"
  },
  ConfirmPayment: {
    component: ConfirmPayment,
    path: "/confirmpayment/:navigationBarTitleText"
  },
  VogueList:{
    component: VogueList,
    path: "/voguelist"
  },
  VogueDetails:{
    component: VogueDetails,
    path: "/voguedetails/:navigationBarTitleText"
  },
  LiveRequest: {
    component: LiveRequest,
    path: "/liverequest"
  },
  AllEvent:{
    component: AllEvent,
    path: "/allevent"
  },
  EventDetail:{
    component: EventDetail,
    path: "/eventdetails/:navigationBarTitleText"
  },
  SelectVenue:{
    component: SelectVenue,
    path: "/selectvenue/:navigationBarTitleText"
  },
  SelectTier:{
    component: SelectTier,
    path: "/selecttier/:navigationBarTitleText"
  },
  ListedTicket:{
    component: ListedTicket,
    path: "/listedticket/:navigationBarTitleText"
  },
  PaymentSelectionEvent:{
    component: PaymentSelection,
    path: "/paymentselections/:navigationBarTitleText"
   },
   EventTrailer:{
    component: EventTrailer,
    path: "/eventtrailer/:navigationBarTitleText"
  },
  TransactionHistory: {
    component: TransactionHistory,
    path: "/TransactionHistory"
  },
  EventViewTicket:{
    component: EventViewTicket,
    path: "/eventviewticket/:navigationBarTitleText"
  },
  EventListToSell:{
    component: EventListToSell,
    path: "/eventlisttosell/:navigationBarTitleText"
  },
  ITOBookingHistory: {
    component: ITOBookingHistory,
    path: "/itobookinghistory"
  },
  ItoListing: {
    component: ItoListing,
    path: "/itolisting"
  }
};

if(firebase.messaging.isSupported()) {
  firebase.initializeApp({
    apiKey: "AIzaSyCOCrjfYXd6o_1NU73X9Y2keliIMPow8YA",
    authDomain: "ticketxchange-c3e9d.firebaseapp.com",
    projectId: "ticketxchange-c3e9d",
    storageBucket: "ticketxchange-c3e9d.appspot.com",
    messagingSenderId: "454055361543",
    appId: "1:454055361543:web:d1ccb0f310dee419e47ea2",
    measurementId: "G-X7VP099BLH"
  });
}


class App extends Component {

  messagingUnsubscribeCall;
  messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

  registerFcmToken(userToken, token) {
    callApi({
      contentType: "application/json",
      method: "POST",
      endPoint: `/account_block/device_ids`,
      headers: { "token": userToken },
      body: { device_token: token }
    }, runEngine)
    setStorageData((LocalStorageKeys.FCMToken), token)
  }

  async generateFcmToken() {

    const userToken = await getStorageData(LocalStorageKeys.LoginToken)
    const fcmToken = await getStorageData(LocalStorageKeys.FCMToken)

    'Notification' in window && Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        if (fcmToken) this.registerFcmToken(userToken, fcmToken)
        else {
          this.messaging && this.messaging.getToken({
            vapidKey: "BPo5FHQBTadXElKWCX5sON92SujVe_QnQOpoFDiMxu-nWVFPlh5h6bqvzEiAlZC8EhREAkG6k3XgtZ_6snM3to4"
          })
            .then((token) => {
              console.log("FIREBASE TOKEN: ", token)
              this.registerFcmToken(userToken, token)
            })
            .catch(() => alert("Something went wrong for Notification!"))
        }
      }
    });

  };

  /**
   * Register user for Notification who is logged in and browser has permission "granted" for notification
   */

  async registerUserHavingNotificationPermission() {

    if('Notification' in window && Notification.permission === "granted") {
      const userToken = (await getStorageData(LocalStorageKeys.LoginToken, false))
      const fcmToken = (await getStorageData(LocalStorageKeys.FCMToken))

      if(userToken && !fcmToken) {
        this.generateFcmToken()
      }
    }
  }

  componentDidMount() {
    window.addEventListener(EventListenerKeys.GenerateFCMToken, this.generateFcmToken)

    this.registerUserHavingNotificationPermission()

    this.messagingUnsubscribeCall = firebase.messaging.isSupported() && firebase.messaging().onMessage((response) => {
      console.log("Firebase Response from app.js", response)
      'Notification' in window && new Notification(response.notification.title || "Ticket Xchange", { body: response.notification.body });
    });
  }

  componentWillUnmount() {
    typeof this.messagingUnsubscribeCall === "function" && this.messagingUnsubscribeCall()
    window.removeEventListener(EventListenerKeys.GenerateFCMToken, this.generateFcmToken)
  }
   
  render() {
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <PushNotificationPermission />
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        {/* <ModalContainer /> */}
      </View>
    );
  }
}

export default App;