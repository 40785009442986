import React from "react";

// Customizable Area Start

import {
    Box,
    Paper,
    Typography,
    Container,
    Button
} from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles, styled } from "@material-ui/core/styles";
import { AddFavourites } from "../../favourites/src/AddFavourites.web";
import { backarrowleft} from "../../ordermanagement/src/assets";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { whiteHeart, filled_heart, imgShare } from "../../reservations2/src/assets";
import Share from "../../share/src/Share.web";
import ReactPaginate from 'react-paginate';
import Loader from "../../../components/src/Loader.web";
import moment from "moment";

const ReactPaginateComp = styled(ReactPaginate)(({ theme }) => ({
  display:"flex",
  color:"#fff",
  fontSize:18,
      fontFamily:"Poppins",
  '& > li':{
    listStyle:"none",
    width: 25,
    height: 25,
    marginLeft: 10,
    textAlign: "center",
    cursor:"pointer"
  },
  '& .selected':{
    background:"#ffb636",
    borderRadius:4,
    color:"#000"
  }
}))

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h1: {
            fontSize: '24px',
            fontWeight: 600
        },
        h3:{
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontWeight:"normal",
            color: '#fff',
          },
          h4:{
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: "normal",
            color: '#b4b4b4',
          },
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End

import VogueListController, {
    Props,
} from "./VogueListController";

class VogueList extends VogueListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const shareAttributies = {
            shareSrc: imgShare,
            imageStyle: webStyle.favImgStyles
          }
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Loader loading={this.state.loading}/>
                <Paper elevation={0}>
                    <MainHeaderComp navigation = {this.props.navigation} />
                    <Box className={classes.WrapperBoxs} data-test-id="cancel">
                        <Container>
                            <Box className={classes.wrapperHeads} data-test-id="viewHead">
                                <img src={backarrowleft} alt="back" className={classes.imageSize} data-test-id="back" onClick={this.handleBack} />
                                <Typography component={'p'} data-test-id="heading" variant="h1">Vogue</Typography>
                            </Box>

                            <Box className={classes.movieCard} style={{borderBottom:this.state.vogueList.length == 0 ? "none" :"1px solid #404040"}}>
                                {this.state.catTypeList?.map((elm: any) => <Button
                                    onClick={() => this.handleOnClickCategory(elm.id)}
                                    key={elm.id}
                                    data-test-id = "category_btn"
                                    className={
                                        this.state.selectedCat == elm.id
                                            ? classes.categorySelectedBtn
                                            : classes.categoryBtn
                                    }>
                                    {elm.name}</Button>)}
                            </Box>

                            {this.state.vogueList?.length > 0 ?
                                <>
                                    {this.state.vogueList.map((elm: any) => {
                                        const { title, is_favourite, files, created_at } = elm.attributes;
                                        const addFavourites = {
                                            heartIcon: whiteHeart,
                                            filledHeart: filled_heart,
                                            imageStyle: { ...webStyle.favImgStyles, marginRight: 15, height:22 },
                                            favouriteable_id: elm.id,
                                            favouriteable_type: "BxBlockCategories::Vogue",
                                            tooltipDirection: "bottom",
                                            isFavourite: is_favourite
                                        }
                                        const image =  files?.find((file:any) => file.match(/\.(jpg|jpeg|png|gif)$/i))
                                        return <Box
                                            key={elm.id}
                                            className={classes.movieCard}
                                            style={{ cursor: "pointer", width: "calc(100%-300)" }}
                                            onClick={() => this.navigateToDetails(elm.id)}
                                            data-test-id="navigateToDetails"
                                        >
                                            {image ? <img className={classes.movieCardImage} src={image} /> : <Box className={classes.movieCardImage} />}
                                            <Box style={{ width: "calc(100% - 140px)" }}>
                                                <Typography variant="h3">{title}</Typography>
                                                <Box className={classes.movieTypo}>
                                                    <Typography variant="h4">
                                                        Published {moment().diff(moment(created_at), 'days')} day
                                                    </Typography>
                                                    <Box onClick={(event) => event.stopPropagation()} style={{ display: "flex" }}>
                                                        <AddFavourites addFavourites={addFavourites} navigation={""} id={""} />
                                                        <Share shareDetails={shareAttributies} navigation={''} id={""} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    })}

                                    <Box data-test-id="pagination-box" style={{ display: "flex", justifyContent: "flex-end", maxWidth:"734px", width:"100%" }}>
                                        <ReactPaginateComp
                                            data-test-id="pagination"
                                            nextLabel=">"
                                            onPageChange={(e) => this.handleVogueOnPageChange(e)}
                                            pageCount={this.state.pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                        />
                                    </Box>
                                </>
                                :
                                <Box style={{ margin: "100px 0px", textAlign: "center" }}>
                                    <Typography variant="h1">No Vogues yet!</Typography>
                                </Box>
                            }
                           
                        </Container>
                    </Box>
                    <FooterWithEvents />
                </Paper>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = () => ({
    WrapperBoxs: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '18px',
        fontFamily: 'Poppins',
        color: '#fff',
        margin: '12px 9px 9px 0px',
    },
    imageSize: {
        width: '32px',
        height: '32px',
        cursor: 'pointer' 
    },
    wrapperHeads: {
        display: 'flex',
        alignItems: 'center',
        gridGap: '15px',
        marginBottom: '22px',
        marginTop: '18px'
    },
    categoryList:{

    },
    movieCard:{
        width:"100%", 
        maxWidth:"734px", 
        display:"flex", 
        borderBottom:"1px solid #404040", 
        padding:"24px 0px",
        alignItems: "center",
        overflowX: "scroll" as const,
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
      movieCardImage:{
        width:124, 
        height:100, 
        marginRight:16
      },
      movieTypo:{
        display:"flex", 
        justifyContent:"space-between",
        marginTop:15,
      },
    categoryBtn: {
        border: "solid 1px #b4b4b4",
        backgroundColor: "#2f2617",
        padding: "8px 16px",
        borderRadius: "12px",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        textTransform:"none" as const,
        color: "#fff",
        marginRight:16,
        minWidth: "unset",
        textWrap: "nowrap",
        '&:hover': {
            backgroundColor: "#2f2617",
        }
    },
    categorySelectedBtn: {
        backgroundColor: "#ffb636",
        padding: "8px 16px",
        borderRadius: "12px",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        textTransform:"none" as const,
        color: "#0b0b0b",
        marginRight:16,
        minWidth: "unset",
        textWrap: "nowrap",
        '&:hover': {
            color: "#0b0b0b",
            backgroundColor: "#ffb636",
        }
    },
})

const webStyle = {
    favImgStyles: {
        height: '24px',
        width: '24px',
        cursor: "pointer"
    },
}

export default (withStyles(styles)(VogueList))
export { VogueList }
// Customizable Area End
